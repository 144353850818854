import React from 'react';
import { withAccessControl } from '../../../pages/service/with-access-control';
import { DashboardProps } from './types';
import KPI from '../KPI';
import SourceAndDumpPoints from '../sourceAndDumpPoints';
import ShiftLoader from '../shiftLoader';
const DEFAULT_SELECTED_TAB = 1; // the default tab should be `Source & Dump Points`

const MaterialMovements: React.FunctionComponent<DashboardProps> = (props) => {
  const { selectedTab } = props;

  return (
    <React.Fragment>
      {selectedTab === 0 && <KPI />}
      {selectedTab === 1 && <SourceAndDumpPoints />}
      {selectedTab === 2 && <ShiftLoader />}
    </React.Fragment>
  );
};

export default withAccessControl(MaterialMovements, DEFAULT_SELECTED_TAB);
