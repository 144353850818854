import { FunctionComponent, useEffect, useState } from 'react';
import { BinDetailsItem, BinDetailsProps } from './types';
import { BinWithPickup } from '../../utils/types';
import { renderBinDetailsItems, renderBinDefaultDetailsItems } from './renders';
import { getContentForBinDetails } from './helper';
import Grid from '@mui/material/Grid';
import './styles.css';

export const BinDetails: FunctionComponent<BinDetailsProps> = (
  props: BinDetailsProps,
) => {
  const [binWithPickupData, setBinWithPickupData] = useState<BinWithPickup>();
  const [binDetailsContents, setBinDetailsContents] =
    useState<BinDetailsItem[]>();

  useEffect(() => {
    setBinWithPickupData(props.binWithPickupData);
  }, [props]);

  useEffect(() => {
    if (binWithPickupData !== undefined) {
      setBinDetailsContents(getContentForBinDetails(binWithPickupData));
    }
  }, [binWithPickupData]);

  return (
    <Grid
      className="bin-details-grid"
      container
      rowSpacing={2}
      columnSpacing={4}
      marginLeft={3}
      marginTop={3}
    >
      {/* Render the non-constant bin details*/}
      {binDetailsContents && renderBinDetailsItems(binDetailsContents)}

      {/* render the constant bin details that are defaults of a bin */}
      {binWithPickupData && renderBinDefaultDetailsItems(binWithPickupData)}
    </Grid>
  );
};
