import { capitaliseFirstLetter } from '../utils/helper';
import { getDurationAgo, getLastLocation } from '../utils/helpers';
import {
  BinWithPickup,
  PickupStatus,
  TruckDataWithAvailability,
  TruckStatusForList,
} from '../utils/types';
import { FlyoutField } from './types';

export const getBinFlyoutFields = (bin: BinWithPickup): FlyoutField[] => {
  const fields: FlyoutField[] = [
    {
      label: 'Bin Level',
      value: `${bin?.binLevel ?? '0'}%`,
    },
    {
      label: 'Last Seen',
      value: getLastLocation(bin.lastLocation),
    },
  ];
  if (bin.lastPickup?.status === PickupStatus.IN_PROGRESS) {
    fields.push({
      label: 'Truck',
      value: bin.lastPickup.truckName,
    });
  } else if (bin.pickupRequest) {
    fields.push({
      label: 'Pickup Requested',
      value: getDurationAgo(bin.pickupRequest.time),
    });
  } else {
    fields.push({
      label: 'Last Emptied',
      value: getDurationAgo(bin.lastEmptiedTime),
    });
  }
  return fields;
};

export const getTruckFlyoutFields = (
  truck: TruckDataWithAvailability,
): FlyoutField[] => {
  const fields: FlyoutField[] = [
    {
      label: 'Truck Status',
      value: capitaliseFirstLetter(truck.status),
    },
    {
      label: 'Operator',
      value: truck.operator ?? '-',
    },
    {
      label: 'Last Seen',
      value: getLastLocation(truck.lastLocation),
    },
    {
      label: 'Last Load',
      value: getDurationAgo(truck.lastLoad),
    },
  ];
  if (truck.availability === TruckStatusForList.inProgress) {
    return [
      ...fields.slice(0, 2),
      { label: 'Bin ID', value: `Bin ${truck.bin}` },
      ...fields.slice(2),
    ];
  }
  return fields;
};
