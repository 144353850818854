import React from 'react';
import { Table } from 'react-bootstrap';
import { getTime, getZones } from '../../utils/helper';
import Header from './header';

export const groupJob = (list) => {
  if (!list) return {};
  return list?.reduce((prev, job) => {
    const key = job.type ? 'Unscheduled' : 'Scheduled';
    if (!prev[key]) prev[key] = [job];
    else prev[key].push(job);
    return prev;
  }, {});
};

const JobTable = ({ groupedJobs, filteredZones }) => (
  <Table id="pdf-table">
    <thead className="table-head">
      <tr>
        <th className="ref-no-td">Ref No</th>
        <th>Location</th>
        <th>Truck</th>
        <th className="duration-td">Job Duration</th>
        <th>Pump Activity</th>
        <th className="water-td">Water Consumed</th>
        <th>Operator</th>
      </tr>
    </thead>
    <tbody>
      {groupedJobs.map((job, index) => (
        <tr key={index}>
          <td>{job?.number || '-'}</td>
          <td>{getZones(job, filteredZones)}</td>
          <td>{job?.truck.name}</td>
          <td>
            {job.type === 'unscheduled' ? (
              '-'
            ) : (
              <>
                <div>
                  {getTime(job?.start_time)} -{' '}
                  {job?.end_time ? getTime(job?.end_time) : 'Ongoing'}
                </div>
                <div>({formatTime(job?.jobMinutes, false)})</div>
              </>
            )}
          </td>
          <td>{formatTime(job?.pumpMinutes, true)}</td>
          <td>{job?.litres + ' L'}</td>
          <td>
            {(job?.operator?.first_name ?? '-') +
              ' ' +
              (job?.operator?.last_name ?? '')}
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export const formatTime = (totalMinutes, includeSeconds = true) => {
  if (!totalMinutes) return includeSeconds ? '00:00:00 h' : '00:00 h';

  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);
  const seconds = Math.floor((totalMinutes - Math.floor(totalMinutes)) * 60);

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return includeSeconds
    ? `${formattedHours}:${formattedMinutes}:${formattedSeconds} h`
    : `${formattedHours}:${formattedMinutes} h`;
};

function JobDailyReport({ jobs, data, date, filteredZones }) {
  const groupedJobs = groupJob(jobs);

  let biggestLitres = 10000;
  Object.values(data).forEach((val) => {
    if (val && typeof val === 'object' && 'waterUsage' in val) {
      if (val.waterUsage > biggestLitres) {
        biggestLitres = val.waterUsage;
      }
    }
  });

  return (
    <div className="pdf-view">
      <Header />
      <h5 className="my-4">
        <b>Dust Control Operations</b>
      </h5>

      <div className="d-flex justify-content-between mb-4">
        <div className="summary-card">
          <div>Total Water Consumption</div>
          <div>{data.totalWaterUsage} L</div>
        </div>

        <div className="summary-card">
          <div>Scheduled Jobs</div>
          <div> {groupedJobs.Scheduled?.length ?? data.scheduledCount}</div>
        </div>
        <div className="summary-card">
          <div>Unscheduled Jobs</div>
          <div> {groupedJobs.Unscheduled?.length ?? data.unscheduledCount}</div>
        </div>
      </div>

      <div className="truck-details">
        <div className="job-report-heading">Truck Details</div>
        <Table id="pdf-table">
          <thead className="table-head">
            <tr>
              <th>Truck</th>
              <th>
                <div>Engine Hours</div>
                <div>(Shift Start: 12:00 AM)</div>
              </th>
              <th>
                <div>Engine Hours</div>
                <div> (Shift End: 11:59 PM)</div>
              </th>
              <th>
                <div>Engine Hours</div>
                <div>(Shift Total)</div>
              </th>
              <th>
                <div>Pump Activity</div>
                <div>(Shift Total)</div>
              </th>
            </tr>
          </thead>
          <tbody className="table-lower">
            {data.trucks &&
              data.trucks.map((truck) => (
                <tr key={truck.name}>
                  <td>{truck.name}</td>
                  <td>{truck.startingEngineHour ?? '0'} h</td>
                  <td>{truck.engineHour ?? '0'} h</td>
                  <td>{formatTime(truck.todayEngineHour, false)}</td>
                  <td>{formatTime(truck.pumpTime, true) || 0} </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>

      <div className="job-report-heading">Zone Breakdown</div>
      <Table id="pdf-table">
        <thead className="table-head">
          <tr>
            <th>Location</th>
            <th>Water Consumed (Shift Total)</th>
            <th>
              <div>Pump activity</div>
              <div>(Shift Total)</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4, 5, 6, undefined].map((zone, index) => {
            const zoneKey = `zone${zone}`;
            const zoneWater = data[zoneKey]?.waterUsage ?? 0;
            let zoneName = `Zone ${zone}`;
            if (!zone) zoneName = 'Undefined';

            const style = { backgroundColor: '#A8AFBF' };

            // the width of the zone bar will be based on the fraction of the zone with the largest litres
            style.width = `${(zoneWater / biggestLitres) * 205}px`;
            if (index % 2) style.backgroundColor = '#5C6371';
            return (
              <tr className="zone-breakdown" key={`${zone}`}>
                <td>{zoneName}</td>
                <td>
                  <div className="litrage-container">
                    <div className="litrage">{zoneWater + ' L'}</div>
                    <div style={style}></div>
                  </div>
                </td>
                <td>{formatTime(data[zoneKey]?.pumpTime ?? 0, true)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      {jobs && (
        <>
          <div className="job-report-heading pt-3">
            Job Report ({jobs?.length} Jobs -{' '}
            {groupedJobs.Scheduled?.length ?? 0} Scheduled &{' '}
            {groupedJobs.Unscheduled?.length ?? 0} Unscheduled)
          </div>
          {groupedJobs.Unscheduled?.length && (
            <>
              <div className="job-type-header">Unscheduled</div>
              <JobTable
                groupedJobs={groupedJobs.Unscheduled ?? []}
                filteredZones={filteredZones}
              />
            </>
          )}
          {groupedJobs.Scheduled?.length && (
            <>
              <div className="job-type-header">Scheduled</div>
              <JobTable
                groupedJobs={groupedJobs.Scheduled}
                filteredZones={filteredZones}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default JobDailyReport;
