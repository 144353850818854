import { FunctionComponent } from 'react';

type HistoryBlackProps = {
  height: number;
  width: number;
};

export const HistoryBlack: FunctionComponent<HistoryBlackProps> = (
  props: HistoryBlackProps,
) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 23.6 23.6">
    <g
      id="Group_46439"
      data-name="Group 46439"
      transform="translate(-314.592 -52.2)"
    >
      <circle
        id="Ellipse_3335"
        data-name="Ellipse 3335"
        cx="11"
        cy="11"
        r="11"
        transform="translate(315.392 53)"
        fill="none"
        stroke="#3c3b3b"
        stroke-linejoin="round"
        stroke-width="1.6"
      />
      <path
        id="Path_16354"
        data-name="Path 16354"
        d="M321,57v7.615l4.231,2.538"
        transform="translate(5.392 -0.615)"
        fill="none"
        stroke="#3c3b3b"
        stroke-width="1.6"
      />
    </g>
  </svg>
);
