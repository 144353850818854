import { useCallback, useState, FC } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import pdf from '../../assets/icons/pdf.svg';
import removeLogo from '../../assets/icons/remove.svg';
import Uploadimage from '../../assets/icons/upload.svg';
import BasicButton from '../BasicButton';

interface ImageUploaderProps {
  selectedImage: string | null;
  setSelectedImage: (image: string | null) => void;
  setFile: (file: File | null) => void;
}

const ImageUploader: FC<ImageUploaderProps> = ({
  selectedImage,
  setSelectedImage,
  setFile,
}) => {
  const [fileTypeError, setFileTypeError] = useState<string | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Reset the file type error
      setFileTypeError(null);

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
          if (reader.result) {
            setFile(file);
            setSelectedImage(reader.result.toString());
          }
        };
        reader.readAsDataURL(file);
      });
    },
    [setSelectedImage, setFile],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    onDropRejected: (fileRejections: FileRejection[]) => {
      const unsupportedFiles = fileRejections.filter(
        (rejection) =>
          !['image/png', 'image/jpeg', 'image/jpg'].includes(
            rejection.file.type,
          ),
      );

      if (unsupportedFiles.length > 0) {
        setFileTypeError(
          'Unsupported file type. Please select a PNG, JPEG, or JPG file.',
        );
      }
    },
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    maxSize: 1000 * 1024, // 100KB
  });

  const removeImage = () => {
    setSelectedImage(null);
    setFile(null); // Clear the selected file
    setFileTypeError(null); // Clear any previous file type error
  };

  const handleCrossButtonClick = () => {
    removeImage();
  };

  return (
    <div
      {...getRootProps()}
      style={{
        marginTop: '20px',
        height: '150px',
        width: '300px',
        borderRadius: '10px',
        border: !selectedImage ? '1px dashed grey' : ' ',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: !selectedImage ? '#FFF' : '#F7F7F7',
        padding: '10px',
      }}
    >
      <input {...getInputProps()} />
      {selectedImage ? (
        <div>
          <div className="d-flex justify-content-center ">
            <div className="position-relative">
              <img style={{ width: '40px', height: '40px' }} src={pdf} alt="" />
              <div
                className="position-absolute "
                style={{ right: '0px', top: '-5px' }}
              >
                <div onClick={handleCrossButtonClick}>
                  <img
                    style={{ width: '15px', height: '15px' }}
                    src={removeLogo}
                    alt="preview"
                  />
                </div>
              </div>
            </div>
          </div>
          <p className="mt-2"> Preview</p>
          <div
            className="d-flex  flex-column justify-content-center align-items-center"
            style={{
              background: '#fff',
              borderRadius: '10px',
            }}
          >
            <embed
              src={selectedImage}
              style={{ maxWidth: '50%', maxHeight: '60px' }} // Image will not overflow the container
            />
          </div>
        </div>
      ) : (
        <>
          {fileTypeError && <p style={{ color: 'red' }}>{fileTypeError}</p>}
          <div style={{ color: '#aaa', marginTop: '10px' }}>
            Drag and drop your signature
          </div>
          <div
            style={{
              position: 'relative',
              maxWidth: '80%',
              maxHeight: '50%',
              marginTop: '10px',
            }}
          >
            <img
              style={{ maxWidth: '33px', maxHeight: '27px' }}
              src={Uploadimage}
              alt="Upload Image"
            />
          </div>
          <div>
            <BasicButton
              name="Save"
              className="mt-3"
              style={{
                backgroundColor: 'white',
                color: 'black',
                height: '25px',
              }}
              onClick={open}
              outlined={false}
              size="sm"
              disabled={false}
              id="save-button"
            >
              Browse Files
            </BasicButton>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageUploader;
