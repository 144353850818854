import React from 'react';
import './styles.scss';
import { JobCycleProps } from './types.js';
import LabelWithLine from './LabelWithLine';
import { HistoryTimeline } from './Timeline';

const PickupTimeline: React.FC<JobCycleProps> = ({ pickup }) => {
  return (
    <>
      <LabelWithLine title={''} />

      <HistoryTimeline pickup={pickup} />
    </>
  );
};

export default PickupTimeline;
