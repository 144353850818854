let accessToken = '';

export const setAccessToken = (s) => {
  localStorage.setItem('accessToken', s);

  return (accessToken = s);
};

export const getAccessToken = () => {
  return accessToken;
};
