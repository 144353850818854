import { Container, Row, Col, Card } from 'react-bootstrap';

const KPI = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '95vh',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Row>
        <Col md={10} className="mx-auto">
          {' '}
          <Card
            style={{
              padding: '2rem',
              borderRadius: '8px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Card.Body className="text-center">
              <Card.Title
                as="h1"
                style={{
                  fontSize: '3rem',
                  color: '#333',
                  marginBottom: '1rem',
                }}
              >
                Page Coming Soon
              </Card.Title>
              <Card.Text
                as="p"
                style={{ fontSize: '1.2rem', color: '#666', lineHeight: '1.5' }}
              >
                We're working hard to bring you an amazing experience. Stay
                tuned!
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default KPI;
