import { BIN, BINS, TRUCK, TRUCKS } from '../../utils/constants';
import { BinWithPickup, MODULES, TruckData } from '../../utils/types';
import { BinRow } from './BinRow';
import { TruckRow } from './TruckRow';
import { GroupedItemProps } from './types';

export const GroupedItem: React.FunctionComponent<GroupedItemProps> = (
  props: GroupedItemProps,
) => {
  if (!props.group.data || props.group.data.length === 0) return null;

  const textColor = props.group.titleColor === '#FFF7B9' ? '#000' : '#FFF';

  return (
    <div
      className="grouped-row"
      style={{
        backgroundColor: props.group.backgroundColor,
      }}
    >
      <div
        className="inner-margin group-title"
        style={{ backgroundColor: props.group.titleColor, color: textColor }}
      >
        {props.group.title} ({props.group.data.length}{' '}
        {props.group.module === MODULES.TRUCKS
          ? props.group.data.length > 1
            ? TRUCKS
            : TRUCK
          : props.group.data.length > 1
            ? BINS
            : BIN}{' '}
        )
      </div>
      {props.group.module === MODULES.BINS &&
        props.group.data.map((bin: BinWithPickup, index) => (
          <BinRow
            key={bin._id} // Use bin.id for a unique key
            bin={bin}
            isFirstItem={index === 0}
            onSelectRow={props.onSelectRow}
            backgroundColor={props.group.backgroundColor}
            titleColor={props.group.titleColor}
            isSelected={props.selectedBinId === bin._id}
          />
        ))}
      {props.group.module === MODULES.TRUCKS
        ? props.group.data.map((truck: TruckData, index) => (
            <TruckRow
              key={truck._id}
              onSelectRow={props.onSelectRow}
              truck={truck}
              isFirstItem={index === 0}
              backgroundColor={props.group.backgroundColor}
              isSelected={props.selectedTruckId === truck._id}
              titleColor={props.group.titleColor}
              title={props.group.title}
            />
          ))
        : null}
    </div>
  );
};
