import { FC } from 'react';
import './historyFooter.css';
import { DateRange } from './types';
import { formatDateDDMMYYYY } from '../../utils/helper';
import { BinHistory } from '../../utils/types';
import { getHistoryCount } from './helper';
type HistoryFooterProps = {
  date: DateRange;
  binHistories: BinHistory[];
};
const HistoryFooter: FC<HistoryFooterProps> = (props) => {
  return (
    <footer className="historyFooter-container">
      <span>
        Displaying {getHistoryCount(props.binHistories)} results for{' '}
        <span>
          {formatDateDDMMYYYY(props.date.from)}, 12:00 AM{' '}
          {props.date.to
            ? `- ${formatDateDDMMYYYY(props.date.to)}, 11:59 PM`
            : ''}
        </span>
      </span>
    </footer>
  );
};

export default HistoryFooter;
