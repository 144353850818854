import { FunctionComponent } from 'react';

type DefaultMarkerProps = {
  height: number;
  width: number;
};

export const DefaultRedMarker: FunctionComponent<DefaultMarkerProps> = (
  props: DefaultMarkerProps,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25.893 36.764"
    {...props}
  >
    {/* Add a rectangle for background */}
    <rect width="100%" height="100%" fill="transparent" />

    {/* Paths for marker */}
    <g
      fill="none"
      stroke="rgb(213, 74, 74)"
      strokeWidth={2}
      data-name="Group 19893"
    >
      <path
        d="M12.938 8.708h.014a4.373 4.373 0 0 1 .013 8.745h-.039a4.373 4.373 0 0 1 .012-8.745Z"
        data-name="Path 15886"
        fill="rgb(213, 74, 74)" // Fill color set to red
      />
      <path
        d="M12.946 1a11.947 11.947 0 0 1 11.947 11.951 12.393 12.393 0 0 1-2.042 7.426c-1.621 2.594-9.9 14.628-9.9 14.628s-8.283-12.034-9.9-14.628a12.393 12.393 0 0 1-2.042-7.426A11.947 11.947 0 0 1 12.946 1Z"
        data-name="Path 15887"
        fill="rgb(213, 74, 74)" // Fill color set to red
      />
      {/* Add a circle for the inner dot */}
      <circle cx="12.938" cy="12.938" r="5.5" fill="white" />
    </g>
  </svg>
);
