export const engineHourHeader = [
  'Asset ID',
  'Department',
  'Last updated',
  'Engine hours',
  '',
];

export enum ModalType {
  EDIT = 'edit',
  DOWNLOAD = 'download',
  ATTENTION = 'attention',
}

export enum FilterQuery {
  SEARCH = 'q',
  CATEGORY = 'categories',
}
