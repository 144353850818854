import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { BsVectorPen } from 'react-icons/bs';
import AllPolygons from './AllPolygons';
import Button from './Button';
import CreatePolygon from './CreatePolygon';
import TruckLoads from './TruckLoads';
import './index.css';

const FunctionalButtons = ({
  module,
  type,
  changeMapType,
  setPolygonType = () => {},
  allSourceDumps,
  handleCursor,
  selectedPolygon,
  setSelectedTruckForLoadMarkers,
  selectedTruckForLoadMarkers,
  onResetCreateState = () => {},
  onDemandDetailPopup = () => {},
  onEditPolygonPoints = () => {},
  onSelectPoint = () => {},
  setPopupConfirmation = () => {},
  reloadForcefully = () => {},
  setPopupMessage = () => {},
  getMaterialColor = () => {},
}) => {
  const [activeType, setActiveType] = useState(0);

  const resetPolygonAndMarkers = () => {
    onEditPolygonPoints({});
    onSelectPoint({});
    onResetCreateState();
  };

  const handleCloseModal = () => {
    onDemandDetailPopup({});
    setSelectedTruckForLoadMarkers(null);
    setActiveType(0);
    changeMapType('select');
    resetPolygonAndMarkers();
  };

  useEffect(() => {
    setActiveType(0);
  }, [module]);

  return (
    <div style={{ paddingLeft: '0.8rem' }} className=" m-body">
      <div className="mm-top_heading">
        <div
          className="d-flex"
          style={{ gap: '0.938rem', position: 'fixed', top: '5.75rem' }}
        >
          {[
            {
              title: `Show All ${_?.capitalize(module)} Points`,
              type: 'select',
              visible: true,
              cursor: 'default',
            },
            {
              title: `Show Truck Loads`,
              type: 'select',
              visible: module == 'dump' ? true : false,
              cursor: 'default',
            },
            {
              title: `Create ${_?.capitalize(module)} Point`,
              icon: <BsVectorPen style={{ marginRight: '0.15rem' }} />,
              type: 'create',
              visible: true,
              cursor: 'pencil',
            },
          ].map((button, index) => {
            return (
              <>
                {button?.visible && (
                  <Button
                    key={index}
                    icon={button?.icon}
                    onClick={() => {
                      setActiveType(index + 1);
                      handleCursor(button?.cursor);
                      changeMapType(button?.type);
                      resetPolygonAndMarkers();
                    }}
                    title={button.title}
                    active={activeType == index + 1}
                    style={{}}
                  />
                )}
              </>
            );
          })}
        </div>

        {activeType == 1 && (
          <AllPolygons
            onDemandDetailPopup={onDemandDetailPopup}
            module={module}
            getMaterialColor={getMaterialColor}
            onEditPolygon={(v) => {
              setActiveType(3);
              changeMapType('edit');
            }}
            setPolygonType={setPolygonType}
            onSelectRow={(v) => {
              onEditPolygonPoints(v);
            }}
            setPopupConfirmation={setPopupConfirmation}
            selectedRow={selectedPolygon}
            data={allSourceDumps}
            onClose={handleCloseModal}
            reloadForcefully={reloadForcefully}
          />
        )}

        {activeType == 2 && (
          <TruckLoads
            getMaterialColor={getMaterialColor}
            selectedTruckForLoadMarkers={selectedTruckForLoadMarkers}
            setSelectedTruckForLoadMarkers={setSelectedTruckForLoadMarkers}
            onClose={handleCloseModal}
          />
        )}

        {activeType == 3 && (
          <CreatePolygon
            type={type}
            onSelectPoint={(v) => {
              onSelectPoint(v);
            }}
            reloadForcefully={() => {
              reloadForcefully();
              setActiveType(1);
              changeMapType('select');
              resetPolygonAndMarkers();
            }}
            module={module}
            onEditPolygonPoints={onEditPolygonPoints}
            selectedPolygon={selectedPolygon}
            onClose={handleCloseModal}
            setPopupMessage={setPopupMessage}
          />
        )}
      </div>
    </div>
  );
};

export default FunctionalButtons;
