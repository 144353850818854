import Header from '../Header';
import KPI from '../assets/KPIs.svg';
import ScapBins from '../assets/ScrapBins.svg';
import UserManagment from '../assets/ManageUsers.svg';
import DustControl from '../assets/DustControl.svg';
import MaterialMovements from '../assets/materialMovements.svg';
import Reports from '../assets/Reports.svg';
import AssetTrackingVehicle from '../assets/AssetTrackingVehicles.svg';
import Maintenance from '../assets/maintenance.svg';
import PreStartCheck from '../assets/PreStartChecks.svg';
import AssetTracking from '../assets/AssetTracking.svg';
import Utilization from '../assets/utilization.svg';
import { UserContext } from '../service/userContext';
import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { desiredOrder, links } from '../../dustControl/utils/constants';

export const icons = {
  500: [KPI],
  400: [ScapBins],
  100: [DustControl],
  200: [MaterialMovements],
  300: [Maintenance],
  600: [PreStartCheck],
  700: [UserManagment],
  800: [AssetTracking],
};

export const subSectionIcons = {
  100: [],
  200: [],
  300: [],
  400: [],
  500: [
    AssetTracking,
    ScapBins,
    DustControl,
    MaterialMovements,
    PreStartCheck,
    Maintenance,
    Utilization,
    Reports,
  ],
  600: [],
  700: [],
  800: [AssetTrackingVehicle],
};

const LandingPage = () => {
  const navigate = useNavigate();
  const { userInfo, departments } = useContext(UserContext);
  const updatedDepartments = useMemo(() => {
    const sortedArray = departments.sort((a, b) => {
      const indexA = desiredOrder.indexOf(a.code);
      const indexB = desiredOrder.indexOf(b.code);
      if (indexA === -1) {
        return 1;
      }
      if (indexB === -1) {
        return -1;
      }
      return indexA - indexB;
    });
    return sortedArray;
  }, [departments]);
  const onDepartmentClick = (code) => {
    return navigate(links[code]);
  };

  return (
    <div className="h-auto mb-4 pt-3 px-3 landing-container">
      <Header showLogo />
      <div className="home-container">
        <div class="department-container">
          {updatedDepartments.map(({ name, code, is_restricted }, index) => {
            return (
              <div
                class={`department-item ${
                  is_restricted && !userInfo.departments.includes(code)
                    ? 'department-disabled'
                    : ''
                }`}
                key={index}
                onClick={() => {
                  if (
                    !(is_restricted && !userInfo.departments.includes(code))
                  ) {
                    onDepartmentClick(code);
                  }
                }}
              >
                <img
                  className="department-icon"
                  alt={name}
                  src={icons[code]}
                  key={code}
                />
                <p className="department-name">{name}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
