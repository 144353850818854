import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { DateRangeSelector } from '../../../manageUser/UserFilterModal/DateRangeSelector';
import DropdownField from '../FormDropdown/DropdownField';
import {
  DateRange,
  FilterModalProps,
  OnCheckboxClickParams,
  SelectedFilters,
} from './types';
import { emptyFilters, getInitialDateRangeForFilter } from './helper';
import { flushSync } from 'react-dom';
import CustomButton from '../../../dustControl/components/CustomButton';

const FilterModal: React.FC<FilterModalProps> = (props) => {
  const { modalOpen, modalToggle } = props;
  const [filteredData, setFilteredData] =
    useState<SelectedFilters>(emptyFilters);
  const onCheckboxClick = ({
    fieldKey,
    optionValue,
  }: OnCheckboxClickParams) => {
    setFilteredData((prev) => {
      const filters = { ...prev };
      if (filters[fieldKey].includes(optionValue))
        filters[fieldKey] = filters[fieldKey].filter(
          (selectedValue) => selectedValue !== optionValue,
        );
      else filters[fieldKey] = [...filters[fieldKey], optionValue];
      return filters;
    });
  };

  const onCheckboxSelectAll = (fieldKey: keyof SelectedFilters) => {
    const allFilterOptions = props.filterFields.find(
      (filterField) => filterField.key === fieldKey,
    );

    // check if all options are already selected, if so set field to empty
    if (filteredData[fieldKey].length === allFilterOptions?.options.length) {
      setFilteredData((prev) => ({ ...prev, [fieldKey]: [] }));
    } else
      setFilteredData((prev) => ({
        ...prev,
        [fieldKey]: allFilterOptions?.options.map((option) => option.value),
      }));
  };

  const clearFilter = () => {
    props.setFilterModalDateRange(undefined);
    setFilteredData(emptyFilters);
    props.setSelectedFilters(emptyFilters);
  };

  const saveHandler = () => {
    props.setSelectedFilters(filteredData);

    if (!props.filterModalDateRange) {
      // do not allow the user to pass in a blank date range
      // instead reset to initial date range for filter
      props.setFilterModalDateRange(getInitialDateRangeForFilter());
      flushSync(() => {
        props.setDateRange(getInitialDateRangeForFilter());
        props.onApplyDateRange();
      });
    } else {
      flushSync(() => {
        props.setDateRange(props.filterModalDateRange);
        props.onApplyDateRange();
      });
    }
    modalToggle(false);
  };

  return (
    <div>
      {modalOpen && (
        <Modal
          show={modalOpen}
          onHide={() => modalToggle(false)}
          backdrop="static"
          keyboard={false}
          size="lg"
          dialogClassName="modal-position"
        >
          <Modal.Header>
            <div className="d-flex justify-content-between w-100 align-items-center ms-4 me-2 my-3 font-13">
              <b className="ps-1">Filters</b>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => modalToggle(false)}
              ></button>
            </div>
          </Modal.Header>
          <div className="pt-0 font-13 d-flex flex-column justify-content-between h-100">
            <div className="mx-4 px-2 d-flex flex-column gap-2">
              {/* @ts-ignore */}
              <DateRangeSelector
                dateRange={props.filterModalDateRange}
                setDateRange={props.setFilterModalDateRange}
                hideClearButton
                labelText="Date Range"
              />
              {props.filterFields.map(
                ({ name, options, key, hideSearchBar }) => (
                  <DropdownField
                    name={name}
                    key={name}
                    fieldKey={key}
                    selectedOptions={filteredData[key]}
                    allOptions={options}
                    multiselect
                    upperLevelClassName="d-flex flex-row justify-content-between"
                    handleChange={onCheckboxClick}
                    noSearch={hideSearchBar}
                    // @ts-ignore
                    onCheckboxSelectAll={onCheckboxSelectAll}
                  />
                ),
              )}
            </div>
            <div className="d-flex align-items-center justify-content-between pt-3 p-4 border-top bg-light">
              <button
                className="border border-0 bg-transparent"
                onClick={clearFilter}
              >
                <u>Clear all</u>
              </button>
              {/* @ts-ignore */}
              <CustomButton
                loading={false}
                id="applyFilters"
                title={'Apply'}
                onClick={saveHandler}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default FilterModal;
