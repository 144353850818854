import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './style.css';
import { customStyle } from './style';

interface CircularProgressBarProps {
  target: number;
  current: number;
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  target,
  current,
}) => {
  return (
    <CircularProgressbar
      value={(current * 100) / target}
      strokeWidth={12.5}
      text={`${current}`}
      background={true}
      backgroundPadding={5}
      styles={buildStyles(customStyle)}
    />
  );
};

export default CircularProgressBar;
