import { colorCategories } from './types';

export const WebSocketURL =
  process.env.REACT_APP_ENV === 'staging'
    ? 'wss://ws.optitrac.com/steelserv-staging?application='
    : 'wss://ws.optitrac.com/steelserv-production?application=';

export enum SocketRoom {
  SCRAP_BINS = 'scrap_bins',
  ENGINE_HOURS = 'engine_hours',
  WATER_CARTS = 'water_carts',
}

export enum SocketClient {
  ADMIN = 'admin',
}

export type EngineHoursSocketData = {
  engineStatus: EngineEvent;
  truckName: string;
  engineHours?: {
    truckName: string;
    deviceId: string;
    calculatedEngineHours: number;
    calculatedEngineMinutes: number;
    totalEngineHoursInMinutes: number;
    currentEngineStatus: EngineEvent;
    lastUpdatedTime?: Date;
  };
};

export enum EngineEvent {
  ON = 'ON',
  OFF = 'OFF',
}

export const truckColors: { [key: number]: string } = {
  8: '#2256E4', // Excavator
  7: '#8FC4FF', // Loader
  1: '#B9DBFF', // Water Truck
  11: '#E5F2FF', // Dump Truck
  4: '#FFB400', // Bin Truck
  5: '#FF9300', // Sludge Truck
  10: '#FF3B2B', // Ultes
  3: '#000000', // Gascavator
  0: '#FF9388', // Misc
};

export const SpeedEventColors = {
  30: '#FEC404',
  40: '#FBA44E',
  50: '#FF6124',
};
export const MaterialDumpingCycleColors = {
  1: '#FF8B30',
  2: '#FFC440',
  3: '#FFDB41',
  4: '#CECECE',
};
export const MaterialColors = [
  { start: '#0F63EF', stop: '#7B9BFC' },
  { start: '#3585FF', stop: '#549EFF' },
  { start: '#80B6FF', stop: '#C7E0FF' },
  { start: '#84A6FD', stop: '#C5D4FA' },
  { start: '#A6AAC1', stop: '#A6AAC1' },
  { start: '#00276A', stop: '#B4C6FF' },
  { start: '#002E7B', stop: '#4664BE' },
  { start: '#000815', stop: '#505D84' },
  { start: '#0098E8', stop: '#C7E5FF' },
  { start: '#80D3FF', stop: '#C7E5FF' },
];
export const BinPickJobColors = {
  1: '#FF8B30',
  2: '#CECECE',
};

export const preStartChecksColors = {
  0: '#85D813',
  1: '#FF5341',
  2: '#EBEBEB',
};
export const ShiftLoaderColors = {
  0: '#FF5341', // StoppedTime
  1: '#85D813', // Runtime
};

export const zoneColors = {
  1: '#004CDE',
  2: '#559FFF',
  3: '#C3DCFF',
  4: '#69CBFF',
  5: '#00A8FF',
  6: '#003D91',
  Outside: '#000000',
};

export const TowerColors = {
  'Tower 1': '#C3D3FF',
  'Tower 2': '#FFD947',
  Unkonwn: '#D2D1D8',
};
