import { FunctionComponent } from 'react';

type TruckIconProps = {
  height: number;
  width: number;
};

export const TruckIconRed: FunctionComponent<TruckIconProps> = (
  props: TruckIconProps,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 109.416 70.374"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_14771"
          data-name="Rectangle 14771"
          width={109.166}
          height={70.374}
          fill="none"
          stroke="#2b2d2f"
          strokeWidth={3}
        />
      </clipPath>
    </defs>
    <g
      id="Group_58196"
      data-name="Group 58196"
      transform="translate(440.75 15605.887)"
    >
      <g
        id="Group_57381"
        data-name="Group 57381"
        transform="translate(-440.5 -15605.887)"
      >
        <g id="Group_46000" data-name="Group 46000" clipPath="url(#clip-path)">
          <path
            id="Path_34704"
            data-name="Path 34704"
            d="M32.088,9h15.8a4.539,4.539,0,0,1,3.75,1.982l7.73,11.639h2.27c2.554,0,4.5,1.691,4.5,5.024v13.13a4.513,4.513,0,0,1-4.5,4.547H57.059"
            transform="translate(40.756 11.431)"
            fill="none"
            stroke="#2b2d2f"
            strokeWidth={3}
          />
          <path
            id="Path_34705"
            data-name="Path 34705"
            d="M10.168,55.483H5.628L1.088,41.869,23.789,1H82.813V5.54L73.732,16.889V55.483H44.22"
            transform="translate(1.382 1.27)"
            fill="#d54a4a"
            stroke="#2b2d2f"
            strokeWidth={3}
          />
          <line
            id="Line_2152"
            data-name="Line 2152"
            y1={27.242}
            x2={15.891}
            transform="translate(47.872 11.351)"
            fill="none"
            stroke="#2b2d2f"
            strokeWidth={3}
          />
          <line
            id="Line_2153"
            data-name="Line 2153"
            y1={27.242}
            x2={15.891}
            transform="translate(34.251 11.351)"
            fill="none"
            stroke="#2b2d2f"
            strokeWidth={3}
          />
          <line
            id="Line_2154"
            data-name="Line 2154"
            y1={27.242}
            x2={15.891}
            transform="translate(20.631 11.351)"
            fill="none"
            stroke="#2b2d2f"
            strokeWidth={3}
          />
          <ellipse
            id="Ellipse_3344"
            data-name="Ellipse 3344"
            cx={9.101}
            cy={9.06}
            rx={9.101}
            ry={9.06}
            transform="translate(79.653 49.984)"
            fill="none"
            stroke="#2b2d2f"
            strokeWidth={3}
          />
          <line
            id="Line_2155"
            data-name="Line 2155"
            x2={6.81}
            transform="translate(72.844 56.753)"
            fill="none"
            stroke="#2b2d2f"
            strokeWidth={3}
          />
          <path
            id="Path_34706"
            data-name="Path 34706"
            d="M35.088,21.081H46.438V18.81L41.919,12"
            transform="translate(44.566 15.242)"
            fill="none"
            stroke="#2b2d2f"
            strokeWidth={3}
          />
          <ellipse
            id="Ellipse_3345"
            data-name="Ellipse 3345"
            cx={9.101}
            cy={9.06}
            rx={9.101}
            ry={9.06}
            transform="translate(11.509 49.984)"
            fill="none"
            stroke="#2b2d2f"
            strokeWidth={3}
          />
          <ellipse
            id="Ellipse_3346"
            data-name="Ellipse 3346"
            cx={9.101}
            cy={9.06}
            rx={9.101}
            ry={9.06}
            transform="translate(29.67 49.984)"
            fill="none"
            stroke="#2b2d2f"
            strokeWidth={3}
          />
        </g>
      </g>
    </g>
  </svg>
);
