import PopupModal from '../popUp';
import BinDetails from './BinDetails';
import JobDetails from './JobDetails';
import { TrackBinProps } from './types';
import JobCyclePieChart from './JobCyclePieChart';
import PickupTimeline from './PickupTimelineSection';
import { renderHeader, renderLegend } from './helper';
import { renderBinStatusLabelForBinDetails } from '../Details/renders';
import LabelWithLine from './LabelWithLine';
import { useScrapBins } from '../../context/scranBinsContext';
import { BinPickup } from '../../utils/types';
import TruckSubSections from './TruckSubSections';

// Track Bin Modal can be opened from trucks or bins section
export const TrackBinModal: React.FC<TrackBinProps> = ({
  isOpen,
  onClose,
  binPickup, // specified only if not displaying the most recent bin pickup which will be retried from
  truck,
  binName,
  fromHistoryTable,
}) => {
  const { bins } = useScrapBins();

  const bin = bins.find((b) => b.name === binName);

  if (!bin) return null;
  else {
    // in the history section, the binPickup will be passed in
    // in the bins section, binPickup will be empty so that it can be fetched from the useScrapBins bins list
    // this is to ensure any live updates are rendered -- if passed through props live updates will not be rendered
    // this is because the TrackBinModal (as accessed from Bin or Truck details) is stored in a state
    const binPickupForDisplay: BinPickup | undefined =
      binPickup ?? bin.lastPickup;

    return (
      <PopupModal
        isOpen={isOpen}
        onClose={onClose}
        header={renderHeader(
          truck ? truck.name : `Bin ${binName}`,
          binPickup?.pickupRef,
        )}
        headerRightChild={renderLegend()}
        removeHeaderMargin={true}
        flyout={fromHistoryTable}
        downloadIcon={true}
      >
        <div className="d-flex">
          {renderBinStatusLabelForBinDetails(bin, false, true)}
        </div>
        <div className="small-text track-bin-container">
          <div className="details-container bg-light">
            <div className="matching-padding">
              {/* First header of the details section must be ouside the main container for the line to have correct width */}
              <LabelWithLine title={truck ? 'Truck Details' : 'Bin Details'} />
            </div>
            <div className="container-with-side-border">
              {truck ? (
                <TruckSubSections pickup={binPickupForDisplay} truck={truck} />
              ) : (
                <>
                  <BinDetails bin={bin} />
                  {binPickupForDisplay && (
                    <JobDetails
                      pickup={binPickupForDisplay}
                      isTruckSection={false}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div className="job-cycle">
            {binPickupForDisplay && (
              <JobCyclePieChart pickup={binPickupForDisplay} />
            )}
            {binPickupForDisplay && (
              <PickupTimeline pickup={binPickupForDisplay} />
            )}
          </div>
        </div>
      </PopupModal>
    );
  }
};
