import React, { useContext, useEffect, useState } from 'react';
import * as toast from '../../../dustControl/components/toast';
import { API_METHODS, ENDPOINTS } from '../../../dustControl/utils/constants';
import { UserContext } from '../../../pages/service/userContext';
import logo from '../../assets/icons/logo.png';
import AddSign from '../AddSign';
import BasicButton from '../BasicButton';

import LoadingScreen from '../../../dustControl/components/loading';
import { SignatureActionDropdown } from '../../../materialMovements/components/SignatureActionDropdown';
import CloseIcon from '../../assets/icons/cross.svg';
import DownloadIcon from '../../assets/icons/download.svg';
import moment from 'moment';

const color = {
  passed: '#319844',
  failed: '#E3402F',
  incomplete: '#325D88',
};

const Header = ({ data, date }) => {
  return (
    <div className="d-flex pady-2 padx-2 justify-content-between">
      <div className="col-8 d-flex align-items-end ">
        <div>
          <img
            src={logo}
            style={{ height: '50px' }}
            alt="SteelServ"
            className="report-logo"
          />
        </div>
        <div className="d-flex ms-3">
          <div className="pe-2">
            <strong>{data?.assetID} -</strong>
          </div>
          <div>Pre-Start Check Results</div>
        </div>
      </div>
      <div className="col-4 d-flex justify-content-end  align-items-end">
        {moment().format('YYYY-MM-DD') == date
          ? moment().format('hh:mm A')
          : ''}{' '}
        {date?.substring(8, 10)}/{date?.substring(5, 7)}/{date?.substring(0, 4)}
      </div>
    </div>
  );
};

function roundAndGetIntPart(number) {
  const decimalPart = number - Math.floor(number);
  if (decimalPart > 0.95) {
    return { number: Math.ceil(number), exceed: true };
  } else {
    return { number: Math.floor(number), exceed: false };
  }
}

function ReportSection({ tables, index, dataObj, search, date }) {
  return (
    <div className="report-view-pdf p-0">
      <Header
        data={dataObj}
        date={search.get('date') ? search.get('date') : date}
      />

      <div className="d-flex">
        <div className="col-6 d-flex border-el pady-2 padx-2">
          <div className="col-6">
            <strong>Checklist</strong>
          </div>
          <div className="col-6">
            <strong>Answer</strong>
          </div>
        </div>
        <div className="col-6 d-flex border-y-solid pady-2 padx-2">
          <div className="col-6">
            <strong>Checklist</strong>
          </div>
          <div className="col-6">
            <strong>Answer</strong>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div
          style={{ fontWeight: '400' }}
          dangerouslySetInnerHTML={{ __html: tables[index] }}
          className="col-6 d-flex border-r  pady-1 padx-2"
        ></div>

        {tables?.length > index + 1 && (
          <div
            style={{ fontWeight: '400' }}
            className="col-6 d-flex padx-2 pady-1"
            dangerouslySetInnerHTML={{ __html: tables[index + 1] }}
          ></div>
        )}
      </div>
    </div>
  );
}

const getCategoryName = (truckType, licences, getDepartmentsAndLicenses) => {
  if (licences?.length <= 0) {
    getDepartmentsAndLicenses();
  }
  const license = licences.find((license) => license.code === truckType);

  if (license) {
    return license.name;
  } else {
    return 'cat_not_found';
  }
};

const getDeptname = (
  truckType,
  departments,
  licences,
  getDepartmentsAndLicenses,
) => {
  const license = licences.find((license) => license.code === truckType);
  console.log(license);
  const dept = departments.find((dept) => license.departmentCode === dept.code);
  if (dept) {
    return dept.name;
  } else {
    return 'dept_not_found';
  }
};

const generateTable = (data, maxHeight, maxWidth, index) => {
  let tableHtml =
    '<div  class="w-100" style="font-size: 12px; font-weight : 400">';
  let currentHeight = 0;
  let remainingData = [];
  let isDataFinished = false;

  try {
    for (const entry of data) {
      if (isDataFinished) {
        remainingData.push(entry);
      } else {
        const { checkList, answer, comments, media, key } = entry;
        let temp = '';
        let htmlToPass = '';
        let entryHeight = 0;
        let isDataExceded = false;
        let filledHeight = currentHeight;
        if (currentHeight < maxHeight) {
          temp += `<div class="d-flex flex-column mb-4">`;
          currentHeight += 27;
          entryHeight += 27;
          if (entry?.isSplitted != true) {
            temp += `<div class="d-flex" >`;
            temp += ` <div class="d-flex fw-bold col-6">`;
            temp += `<div class = "col-1 me-2" >${key}</div>`;
            temp += `<div class = "col-10"  >${checkList}</div>`;
            temp += `</div>`;
            const containerWidth = 122; // in pixels 142
            const fontSize = 11; // in pixels
            const charsPerLine = Math.floor(containerWidth / fontSize);
            const numLines = Math.ceil(checkList?.length / charsPerLine);
            currentHeight += 11 * numLines;
            entryHeight += 11 * numLines;

            if (answer == 'faulty') {
              temp += `<div  class="col-6" style="color:#AA7827"  "><strong>Faulty</strong></div>`;
            } else if (answer == 'ok') {
              temp += `<div  class="col-6" style="color:#2A7B39"  "><strong>OK</strong></div>`;
            } else {
              temp += `<div  class="col-6" style="color:#00005B"  "><strong>${answer?.toUpperCase()}</strong></div>`;
            }
            temp += '</div>';
          }

          if (comments?.length > 0 || media?.length > 0) {
            temp += `<div class="d-flex">`;

            if (entry?.noComments != true) {
              if (comments?.length > 0) {
                const containerWidth = 159; // in pixels
                const fontSize = 10.6; // in pixels
                const charsPerLine = Math.floor(containerWidth / fontSize);
                // Calculate the number of lines by dividing the total characters in the comment by characters per line
                const numLines = Math.ceil(comments?.length / charsPerLine) - 1;
                const linesCanBeFilled =
                  Math.ceil((maxHeight - entryHeight - filledHeight) / 10.6) +
                  1;
                const numberOfCharacterThatCanBeFilled =
                  linesCanBeFilled * charsPerLine - 15;
                currentHeight += 10.6 * numLines;
                entryHeight += 10.6 * numLines;
                if (linesCanBeFilled - 9 <= numLines) {
                  temp +=
                    comments.slice(0, numberOfCharacterThatCanBeFilled)
                      ?.length > 0
                      ? `<div style="color:#707070" class="col-6">Comments</div>`
                      : '';
                  temp += `<div class="col-6">${comments.slice(
                    0,
                    numberOfCharacterThatCanBeFilled,
                  )}${
                    comments.slice(
                      numberOfCharacterThatCanBeFilled,
                      comments.length,
                    ).length > 0
                      ? '-'
                      : ''
                  }</div>`;
                  temp += '</div>';

                  htmlToPass = temp;
                  isDataExceded = true;
                  isDataFinished = true;
                  remainingData.push({
                    ...entry,
                    isSplitted: true,
                    comments: comments.slice(
                      numberOfCharacterThatCanBeFilled,
                      comments.length,
                    ),
                  });
                }
                temp += `<div style="color:#707070" class="col-6">Comments</div>`;
                temp += `<div class="col-6">${comments}</div>`;
              }
            }
            temp += '</div>';

            if (media?.length > 0) {
              const totalImages = media?.length;
              const { number, exceed } = roundAndGetIntPart(
                (maxHeight - entryHeight - filledHeight) / 70,
              );
              const imagesCanBeFilled = number;
              let mediaDiv = '';
              if (imagesCanBeFilled >= totalImages) {
                if (imagesCanBeFilled == totalImages && exceed) {
                  isDataFinished = true;
                  isDataExceded = true;
                }
                media.map((mediaInst, index) => {
                  mediaDiv += `<div style=" ${
                    entry?.isSplitted == true
                      ? index != 0
                        ? 'margin-top:10px'
                        : ''
                      : 'margin-top:10px'
                  }" class="d-flex ">`;
                  mediaDiv += `<div style="color:#707070"  class="col-6">${
                    index == 0 ? 'Media' : ''
                  }</div>`;
                  mediaDiv += `<div class="col-6"><img src="${mediaInst}" alt="Image" style="max-height: 60px; height: 60px" class="modal-trigger" data-media="${mediaInst}"></div>`;
                  currentHeight += 70;
                  entryHeight += 70;
                  mediaDiv += '</div>';
                });
              } else {
                for (let i = 0; i < imagesCanBeFilled; i++) {
                  mediaDiv += `<div style="margin-top:10px" class="d-flex ">`;
                  mediaDiv += `<div style="color:#707070"  class="col-6">${
                    i == 0 ? 'Media' : ''
                  }</div>`;
                  mediaDiv += `<div class="col-6"><img src="${media[i]}" alt="Image" style="max-height: 60px; height: 60px" class="modal-trigger" data-media="${media[i]}"></div>`;
                  currentHeight += 70;
                  entryHeight += 70;
                  mediaDiv += '</div>';
                }
                const remainingMedia = media.slice(
                  imagesCanBeFilled,
                  media?.length,
                );
                if (entryHeight <= maxHeight - filledHeight) {
                  htmlToPass = isDataExceded
                    ? htmlToPass + mediaDiv
                    : temp + mediaDiv;
                  if (!isDataExceded) {
                    remainingData.push({
                      ...entry,
                      isSplitted: true,
                      noComments: true,
                      media: remainingMedia,
                    });
                  }
                  isDataExceded = true;
                }
              }
              temp += mediaDiv;
            }
          }
          temp += '</div>';
          if (entryHeight <= maxHeight - filledHeight || isDataExceded) {
            tableHtml += isDataExceded ? htmlToPass + '</div>' : temp;
          } else {
            remainingData.push(entry);
          }
        } else {
          remainingData.push(entry);
        }
      }
    }
  } catch (error) {
    console.log(error);
  }

  tableHtml += '</div>';
  return { tableHtml, remaining: remainingData };
};

const Report1 = ({ id, date, fetchParent }) => {
  const {
    ApiHandler,
    departments,
    licenses,
    getDepartmentsAndLicenses,
    userInfo,
  } = useContext(UserContext);

  async function openModal(imageUrl) {
    const modal = document.getElementById('imageModal');
    const modalImg = document.getElementById('modalImage');
    const downloadImg = document.getElementById('downloadModal'); // Get the download image element

    modal.style.display = 'block';
    modalImg.src = imageUrl;

    // Handle click event for downloading the image
    downloadImg.onclick = async function () {
      try {
        if (imageUrl) {
          try {
            setLoading(true);
            const response = await ApiHandler({
              endPoint: ENDPOINTS.getImage,
              method: API_METHODS.PUT,
              reqParam: {
                url: imageUrl,
              },
              blobType: true,
            });

            const url = window.URL.createObjectURL(new Blob([response?.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'image.png');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false);
          } catch (error) {
            toast.error(error?.message);
            setLoading(false);
          }
        }
      } catch (error) {
        toast.error(error?.message);
      }
    };

    const closeModal = document.getElementById('closeModal');
    closeModal.onclick = function () {
      modal.style.display = 'none';
    };
  }

  const search = new URLSearchParams(document.location.search);

  const [showModal, setShowModal] = useState(false);
  const [dataObj, setObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [signatureData, setSignatureData] = useState({
    signedName: '',
    comments: '',
    signature: '',
    file: '',
    signedAt: '',
  });
  const [tables, setTables] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const fetch = async () => {
    try {
      setIsLoading(true);
      const response = await ApiHandler({
        endPoint: ENDPOINTS.getPrestartChecks,
        method: API_METHODS.PUT,
        reqParam: {
          date: search.get('date') ? search.get('date') : date,
        },
      });
      const ID = search.get('id') ? search.get('id') : id;
      const d = response.data.find((obj) => obj._id === ID);

      let t_summary = [0, 0, 0]; //[failed, passed, incomplete]
      let catData = [];

      let fault = 0;
      function getStatusIndex(status) {
        return ['passed', 'failed', 'incomplete'].indexOf(status);
      }
      for (const status of ['failed', 'passed', 'incomplete']) {
        if (d.status === status) {
          t_summary[getStatusIndex(status)]++;

          // Check if the category exists in catData, otherwise add it
          const categoryName = getCategoryName(
            d?.truck?.type,
            licenses,
            getDepartmentsAndLicenses,
          );
          const categoryIndex = catData.findIndex(
            (cat) => cat.name === categoryName,
          );

          if (categoryIndex === -1) {
            catData.push({ name: categoryName, data: [0, 0, 0] });
          }

          // Update data in catData
          const updatedCategoryIndex = catData.findIndex(
            (cat) => cat.name === categoryName,
          );
          catData[updatedCategoryIndex].data[getStatusIndex(status)]++;
        }
      }
      let faultyIndexes = [];
      d?.checkpoints?.map((c, faultIndex) => {
        if (c.answer == 'faulty') {
          fault++;
          faultyIndexes.push(faultIndex);
        }
      });
      let tempor = {
        ...d,
        assetID: d?.truck?.name,
        failedChecks: `${fault}/${d?.checkpoints?.length}`,
        supervisorSignature: d?.supervisor?.signedName,
        department: getDeptname(
          d?.truck?.type,
          departments,
          licenses,
          getDepartmentsAndLicenses,
        ),
        category: getCategoryName(
          d?.truck?.type,
          licenses,
          getDepartmentsAndLicenses,
        ),
        faultyIndexes,
      };
      setObj(tempor);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      toast.error(err?.message);
    }
  };

  const fetchCheckpoints = async () => {
    try {
      const response = await ApiHandler({
        endPoint: ENDPOINTS.getCheckPoints,
        method: API_METHODS.GET,
      });
      // if(response?.status == 200){}
      const checkpoints = response.data[0]?.checkpoints;

      const result = dataObj?.checkpoints?.map((checkpoint, index) => {
        const matchingData = checkpoints.find((item) =>
          item.toLowerCase().includes(checkpoint.question.toLowerCase()),
        );

        return {
          key: index + 1,
          checkList: matchingData ? matchingData : checkpoint.question,
          answer: checkpoint.answer,
          media: checkpoint.imagePaths,
          comments: checkpoint?.note, // You need to define what 'note' is
        };
      });

      const temp = [];
      let remainingData = result;
      while (remainingData?.length > 0) {
        const calcuateHeight =
          temp?.length > 1
            ? 905
            : 530 -
              (dataObj?.faultyIndexes?.length > 24
                ? 30
                : dataObj?.faultyIndexes?.length > 15
                  ? 24
                  : dataObj?.faultyIndexes?.length > 8
                    ? 16
                    : 8);
        const { tableHtml, remaining } = generateTable(
          remainingData,
          calcuateHeight,
          200,
          temp?.length,
        );
        temp.push(tableHtml);
        setTables(temp);
        remainingData = remaining;
      }
    } catch (err) {
      console.log(err);

      toast.error(err?.message);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (dataObj?._id) {
      setIsLoading(true);
      fetchCheckpoints();
      setSignatureData({
        signedName: dataObj?.supervisor?.signedName,
        comments: dataObj?.supervisor?.comments,
        signature: dataObj?.supervisor?.signature,
        file: dataObj?.supervisor?.signature,
        signedAt: dataObj?.supervisor?.signedAt,
        _id: dataObj?.supervisor?._id,
      });
      dataObj?.supervisor?._id ? setIsEdit(true) : console.log('');
      setIsLoading(false);
    }
  }, [dataObj]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const formatDate = (timestamp, defaultValue = '00:00 AM') => {
    if (isNaN(Date.parse(timestamp))) return defaultValue;
    const date = new Date(timestamp);
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const getLocalTime = (time) => {
    const timeString = formatDate(time);
    var lastTwoCharacters = timeString.slice(-2); // Get the last two characters
    var modifiedTime =
      timeString.slice(0, -2) + lastTwoCharacters.toLowerCase(); // Convert the last two characters to lowercase and replace them
    return modifiedTime;
  };

  const handleSaveAndSign = async () => {
    const formData = new FormData();
    formData.append(
      'signedName',
      signatureData?.signedName ? signatureData?.signedName : '',
    );
    formData.append('prestartId', dataObj?._id ? dataObj?._id : '');
    if (typeof signatureData?.file == 'string') {
      formData.append('signatureFileUrl', signatureData?.file);
    } else {
      formData.append('files', signatureData?.file);
    }

    formData.append(
      'comments',
      signatureData?.comments ? signatureData?.comments : '',
    );

    try {
      const response = await ApiHandler({
        endPoint: ENDPOINTS.signPrestartCheck,
        method: API_METHODS.POST,
        reqParam: formData,
      });

      if (response?.status == 200) {
        toast.success('Added');
      }
    } catch (error) {
      console.log(error);

      toast.error(error?.message);
    }

    fetchParent();
  };

  const handleDropdownOptions = async (action, sign) => {
    console.log({ action, sign });
    if (action === 'edit') {
      setShowModal(true);
    }
    if (action === 'delete') {
      setSignatureData({
        ...signatureData,
        signedName: '',
        signature: '',
        file: '',
        signedAt: '',
      });
    }
    // if (action === dropdownOptions.delete) {
    //   setModalType("");
    //   if (user.status === UserStatus.invited) {
    //     await cancelInvitation(user._id);
    //   } else {
    //     await deactivateDelete("Deleted", user._id);
    //   }
    // }
  };

  return (
    <>
      {isLoading ? (
        <div>Loading....</div>
      ) : (
        <div
          onClick={() => {
            const images = document.querySelectorAll('.modal-trigger');
            images.forEach((img) => {
              img.addEventListener('click', () => {
                const mediaUrl = img.getAttribute('data-media');
                openModal(mediaUrl);
              });
            });
          }}
          id="print-report"
          className="report-loaded"
        >
          <div id="imageModal" class="modal">
            <div className="download-icon-report gap-3">
              {loading ? (
                <LoadingScreen />
              ) : (
                <span class="download" id="downloadModal">
                  <div className="cursor-pointer text-white">
                    <img src={DownloadIcon} alt="download" />
                  </div>
                </span>
              )}
              <span class="close" id="closeModal">
                <div className="cursor-pointer text-white">
                  <img src={CloseIcon} alt="close" />
                </div>
              </span>
            </div>
            <img
              style={{
                margin: 'auto',
                marginTop: '10vh',
                height: '70vh',
                width: 'max-content',
              }}
              class="modal-content"
              id="modalImage"
              src=""
            />
            <div id="caption"></div>
          </div>

          <div className="report-view-pdf  p-0">
            <Header
              date={search.get('date') ? search.get('date') : date}
              data={dataObj}
            />
            <div className="report-section-2 mb-3">
              <div className="d-flex py-2 padx-2 justify-content-between">
                <div>
                  {' '}
                  Status:{' '}
                  <strong style={{ color: color[dataObj.status] }}>
                    {' '}
                    {dataObj.status?.toUpperCase()}
                  </strong>
                </div>

                <div> Ref No: {dataObj?.refNo} </div>
              </div>

              <div className="d-flex py-2 padx-2 border-y ">
                <div className=" col-5 d-flex flex-column">
                  <div className=" d-flex">
                    <div className="col-6">Department:</div>
                    <div className="col-6">
                      <strong>{dataObj?.department}</strong>
                    </div>
                  </div>
                  <div className=" d-flex">
                    <div className="col-6">Category: </div>
                    <div className="col-6">
                      <strong>{dataObj?.category}</strong>
                    </div>
                  </div>
                  <div className=" d-flex">
                    <div className="col-6">Engine Hours:</div>
                    <div className="col-6">
                      <strong>{dataObj?.engineHours}</strong>
                      {dataObj?._databaseEngineHours
                        ? dataObj?.engineHours != dataObj?._databaseEngineHours
                          ? ' (Manual)'
                          : ' (Automatic)'
                        : ' (Automatic)'}
                    </div>
                  </div>
                </div>
                <div className=" col-5 d-flex flex-column">
                  <div className=" d-flex">
                    <div className="col-6">Faulty Checks: </div>
                    <div className="col-6">
                      <strong>
                        {dataObj?.failedChecks}
                        <span style={{ color: '#909090' }}>
                          <span>
                            {' '}
                            {dataObj?.faultyIndexes?.length > 0 ? '(' : ''}{' '}
                          </span>
                          {dataObj?.faultyIndexes?.map((faultIndex, index) => {
                            return `
                              ${faultIndex + 1}${
                                index + 1 == dataObj?.faultyIndexes?.length
                                  ? ' '
                                  : dataObj?.faultyIndexes?.length == 2
                                    ? '&'
                                    : ','
                              }`;
                          })}
                          <span>
                            {' '}
                            {dataObj?.faultyIndexes?.length > 0 ? ')' : ''}{' '}
                          </span>
                        </span>
                      </strong>
                    </div>
                  </div>
                  <div className=" d-flex">
                    <div className="col-6">Operator:</div>
                    <div className="col-6">
                      <strong>
                        {dataObj?.operator?.first_name
                          ?.charAt(0)
                          .toUpperCase() +
                          dataObj?.operator?.first_name?.slice(1)}{' '}
                        {dataObj?.operator?.last_name?.charAt(0).toUpperCase() +
                          dataObj?.operator?.last_name?.slice(1)}{' '}
                      </strong>
                    </div>
                  </div>
                  <div className=" d-flex">
                    <div className="col-6">Login time:</div>
                    <div className="col-6">
                      <strong>{getLocalTime(dataObj?.loginTime)}</strong>
                    </div>
                  </div>
                </div>
                <div className="col-2 d-flex flex-column">
                  <div>Operator's signature</div>
                  <div>
                    {dataObj?.operator?.signature && (
                      <embed
                        style={{ height: '30px' }}
                        src={dataObj?.operator?.signature}
                        alt="sign"
                      />
                    )}
                  </div>
                  <div> {getLocalTime(dataObj?.checkTime)}</div>
                </div>
              </div>

              <div
                className={`d-flex ${
                  !search.get('date')
                    ? 'align-items-center'
                    : 'align-items-start'
                } justify-content-center `}
              >
                <div className="col-8 py-3 padx-2 d-flex flex-column">
                  <div>Supervisor's use:</div>
                  <div>
                    <strong>Comments</strong>
                  </div>
                  {search.get('date') ? (
                    <div
                      style={{
                        height: '5.5rem',
                        maxHeight: '5.5rem',
                        width: '100%',
                        marginTop: '0.5rem',
                        marginBottom: '0.85rem',
                        resize: 'vertical',
                      }}
                    >
                      {signatureData?.comments ? (
                        signatureData?.comments
                      ) : (
                        <i>No Comments</i>
                      )}
                    </div>
                  ) : (
                    <textarea
                      placeholder="Write a comment..."
                      disabled={
                        signatureData._id
                          ? userInfo._id != signatureData._id
                          : false
                      }
                      value={signatureData?.comments}
                      onChange={(e) => {
                        setSignatureData({
                          ...signatureData,
                          comments: e.target.value,
                        });
                      }}
                      style={{
                        height: '6rem',
                        maxHeight: '6rem',
                        width: '100%',
                        backgroundColor: 'white',
                        color: 'black',
                        borderRadius: '0.33rem',
                        border: '1px solid #EBEBEB',
                        padding: '0.85rem',
                        marginBottom: '0.85rem',
                        resize: 'vertical',
                        disabledColor: 'black',
                      }}
                    />
                  )}

                  <div className="d-flex justify-content-end">
                    {search.get('date') ? (
                      <div style={{ height: '20px' }}></div>
                    ) : (
                      <BasicButton
                        name="Save"
                        style={{ backgroundColor: 'black', color: 'white' }}
                        className="col-6 "
                        onClick={() => handleSaveAndSign()}
                        disabled={
                          !signatureData?.signature ||
                          (signatureData._id
                            ? userInfo._id != signatureData._id
                            : false)
                        }
                      >
                        Save
                      </BasicButton>
                    )}
                  </div>
                </div>
                <div className="col-4 py-3 padx-2 ">
                  <div className="d-flex justify-content-between align-items-start mb-2 ">
                    <div>
                      <strong>Signature</strong>
                    </div>
                    {!signatureData?.signature && !search.get('date') && (
                      <BasicButton
                        name="add"
                        style={{ backgroundColor: 'white', color: 'black' }}
                        onClick={handleShowModal}
                      >
                        Add
                      </BasicButton>
                    )}
                    {showModal && (
                      <>
                        <div
                          style={{
                            position: 'fixed',
                            top: 290,
                            left: 100,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0,0,0,0)',
                            zIndex: 1000,
                          }}
                        >
                          <AddSign
                            closeModal={() => {
                              setShowModal(false);
                            }}
                            onChange={(v) => {
                              let temp = {
                                ...signatureData,
                                signedAt: moment().format(
                                  'hh:mm a - DD/MM/YYYY',
                                ),
                                signature: v.file,
                                signedName: v.signedName,
                              };
                              if (v.f) {
                                temp['file'] = v.f;
                              }
                              setSignatureData(temp);
                            }}
                            props={{
                              signedName: signatureData?.signedName,
                              signature: signatureData?.signature,
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <div className="d-flex w-100 border-y py-2 ">
                    {signatureData?.signature ? (
                      <div className="d-flex w-100 justify-content-between">
                        <div>
                          <embed
                            style={{ maxHeight: '40px', maxWidth: '170px' }}
                            src={signatureData?.signature}
                            alt="sign"
                          />
                          <br />
                          <span className="">{signatureData?.signedName}</span>
                          <br />
                          {/* <span className="">{moment(signatureData?.signedAt).format('hh:mm a - DD/MM/YYYY')}</span> */}
                          <span className="">
                            {signatureData?.signedAt.includes('T')
                              ? moment(signatureData?.signedAt).format(
                                  'hh:mm a - DD/MM/YYYY',
                                )
                              : signatureData?.signedAt}
                          </span>
                        </div>
                        {(signatureData._id
                          ? userInfo._id == signatureData._id
                          : true) && (
                          <SignatureActionDropdown
                            handleDropdownOptions={handleDropdownOptions}
                          />
                        )}
                      </div>
                    ) : (
                      <i className="py-1" style={{ color: '#707070' }}>
                        {search.get('date')
                          ? 'Not Signed'
                          : 'No signature saved'}
                      </i>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex">
              <div className="col-6 d-flex border-el pady-2 padx-2">
                <div className="col-6">
                  <strong>Checklist</strong>
                </div>
                <div className="col-6">
                  <strong>Answer</strong>
                </div>
              </div>
              <div className="col-6 d-flex border-y-solid pady-2 padx-2">
                <div className="col-6">
                  <strong>Checklist</strong>
                </div>
                <div className="col-6">
                  <strong>Answer</strong>
                </div>
              </div>
            </div>

            <div className="d-flex">
              <div
                style={{ fontWeight: '400' }}
                dangerouslySetInnerHTML={{ __html: tables[0] }}
                className="col-6 d-flex border-r  pady-1 padx-2"
              ></div>

              {tables?.length > 1 && (
                <div
                  style={{ fontWeight: '400' }}
                  dangerouslySetInnerHTML={{ __html: tables[1] }}
                  className="col-6 d-flex padx-2 pady-1"
                ></div>
              )}
            </div>
          </div>

          {tables?.map((table, index) => {
            const startIndex = 2 + index * 2;
            if (tables?.length > startIndex) {
              return (
                <ReportSection
                  key={startIndex} // Add a unique key prop for each component
                  tables={tables}
                  index={startIndex}
                  dataObj={dataObj}
                  search={search}
                  date={date}
                />
              );
            }
            return null; // Return null if the condition is not met
          })}
        </div>
      )}{' '}
    </>
  );
};

export default Report1;
