import React, { useEffect, useMemo, useState } from 'react';
import minusIcon from './../assets/svgs/minus.svg';
import plusIcon from './../assets/svgs/plus.svg';
import { isEmpty } from 'lodash';
import {
  getBinBackgroundColorByGeneralStatus,
  getBinColorByGeneralStatusTitles,
  getGeneralBinStatus,
  getListOfCoordinates,
} from '../utils/helpers';
import { BinFlyout } from './BinFlyout';
import { GeneralBinStatus, MODULES } from '../utils/types';
import { TrackBinModal } from '../components/TrackBin';
import {
  truckColorMapping,
  truckStatusLightColorMap,
} from '../utils/constants';

let map;
let mapkit = undefined;
let markerAnnotations = []; // points on map
let currentMarkerFlyout; // detailed popup above point (marker) on map

export const Map = ({
  mapRef,
  module,
  markers = {}, // points on map
  handleItemSelect,
  setShowSection,
  showFlyout,
  selectedItemName,
  handleShowMapFlyout,
}) => {
  const [setupCompleted, setSetupCompleted] = useState(false);
  const [showTrackBinModal, setShowTrackBinModal] = useState(false); // New state for managing modal visibility
  const [selectedMarkerData, setSelectedMarkerData] = useState(null); // New state for storing selected bin data

  // Function to close the Track Bin Modal
  const closeTrackBinModal = () => {
    setShowTrackBinModal(false);
    setSelectedMarkerData(null);
  };

  // remove points from map
  const removeMarkers = () => {
    if (!markerAnnotations?.length) return;
    markerAnnotations.forEach((annotation) => {
      map.removeAnnotation(annotation);
    });
    markerAnnotations = [];
    if (currentMarkerFlyout) currentMarkerFlyout.visible = false;
  };

  // remove detailed popup above point on map
  const removeAnnotation = () => {
    if (currentMarkerFlyout) {
      currentMarkerFlyout.visible = false;
      map.removeAnnotation(currentMarkerFlyout);
    }
  };

  const handleAnnotation = ({ marker, coordinates }) => {
    removeAnnotation();
    if (isEmpty(marker)) return;

    const markerCoord = new mapkit.Coordinate(
      coordinates.latitude,
      coordinates.longitude,
    );

    // Nest the functions and states inside the `data` key of options
    const options = {
      data: {
        marker,
        module,
        handleItemSelect: handleItemSelect, // Passed directly

        setShowTrackBinModal: (shouldShow, binData = null) => {
          // This function should be adjusted as it's part of `data` now
          setShowTrackBinModal(shouldShow);
          if (shouldShow) {
            setSelectedMarkerData(marker);
          } else {
            setSelectedMarkerData(null);
          }
        },
        setShowSection,
      },
      anchorOffset: new DOMPoint(-120, 50),
    };

    let annotation = new mapkit.Annotation(markerCoord, BinFlyout, options);
    map.setCenterAnimated(markerCoord);
    map.addAnnotation(annotation);
    mapRef.current._impl.zoomLevel = 17;

    currentMarkerFlyout = annotation;
    annotation.visible = true;
  };

  // creates the points on the map
  const createMarker = ({ coordinates, marker, isSelected }) => {
    const markerCoord = new mapkit.Coordinate(
      coordinates?.latitude,
      coordinates?.longitude,
    );

    let color = '';
    let paleColor = '';
    let svgContent = '';
    let clusteringIdentifier = '';

    if (module === MODULES.BINS) {
      const binStatus = getGeneralBinStatus(marker);
      color = getBinColorByGeneralStatusTitles(binStatus);
      paleColor = getBinBackgroundColorByGeneralStatus(binStatus);
      clusteringIdentifier = binStatus;

      // handle missing and requested separately
      if (binStatus === GeneralBinStatus.REQUESTED) {
        // SVG content for 'REQUESTED' status
        svgContent = `<?xml version="1.0" encoding="utf-8"?>
      <svg viewBox="0 0 150 150" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
  <!-- Outer circle -->
  <circle cx="60" cy="60" r="40" fill='${color}' stroke="#D5BE08" stroke-width="6" />
  <!-- Inner circle -->
  <circle cx="55" cy="55" r="22" fill="#FFE838" />

  <!-- Exclamation mark inside the circle -->
  <g id="c129_exclamation" transform="translate(37 35) scale(1.7)"> <!-- Adjust the translation and scale as needed -->
    <path d="M13.983,0C6.261,0,0.001,6.259,0.001,13.979c0,7.724,6.26,13.984,13.982,13.984s13.98-6.261,13.98-13.984
      C27.963,6.259,21.705,0,13.983,0z M13.983,26.531c-6.933,0-12.55-5.62-12.55-12.553c0-6.93,5.617-12.548,12.55-12.548
      c6.931,0,12.549,5.618,12.549,12.548C26.531,20.911,20.913,26.531,13.983,26.531z"/>
    <polygon points="15.579,17.158 16.191,4.579 11.804,4.579 12.414,17.158"/>
    <path d="M13.998,18.546c-1.471,0-2.5,1.029-2.5,2.526c0,1.443,0.999,2.528,2.444,2.528h0.056c1.499,0,2.469-1.085,2.469-2.528
      C16.441,19.575,15.468,18.546,13.998,18.546z"/>
  </g>
</svg>`;
      } else if (binStatus === GeneralBinStatus.MISSING) {
        // SVG content for 'REQUESTED' status
        svgContent = `<?xml version="1.0" encoding="utf-8"?>
        <svg viewBox="0 0 150 150" width="30" height="30" xmlns="http://www.w3.org/2000/svg">
      
      <path fill="#B1378A" opacity="1.000000" stroke="none" 
        d="
      M18.060055,87.979485 
        C13.019753,68.814865 16.254107,51.396236 27.158401,35.559059 
        C36.498211,21.994114 49.634510,12.882944 65.559753,9.622013 
        C84.937302,5.654173 103.325859,9.707461 119.108727,22.245529 
        C136.133667,35.770309 144.266632,53.678913 142.600708,75.355408 
        C140.835083,98.329056 130.327942,116.161362 109.733803,127.635437 
        C100.286362,132.899094 90.084335,135.645294 79.611115,135.619843 
        C66.276085,135.587418 53.642563,131.471252 42.640400,123.682381 
        C30.282900,114.934044 22.131058,103.034912 18.060055,87.979485 
      M94.729950,125.073532 
        C95.163574,125.018738 95.597198,124.963943 96.655846,124.843964 
        C96.963455,124.577530 97.271065,124.311096 98.277779,124.142097 
        C99.839676,123.587921 101.452431,123.145752 102.955902,122.462936 
        C129.206284,110.541145 142.077271,79.915123 132.224991,52.883228 
        C122.080925,25.050739 92.643089,9.552155 64.579018,17.853977 
        C41.861622,24.574165 27.782997,40.096058 24.383663,63.440609 
        C21.066750,86.219124 29.238153,104.967880 49.228165,118.602631 
        C50.830231,119.456200 52.432297,120.309769 54.282436,121.237747 
        C54.282436,121.237747 54.437229,121.030098 54.810192,121.610031 
        C55.233879,121.773857 55.657562,121.937683 56.275555,122.180061 
        C56.275555,122.180061 56.428627,122.036896 56.823288,122.559448 
        C57.226242,122.706345 57.629196,122.853233 58.262390,123.136414 
        C58.262390,123.136414 58.529663,123.124168 59.081848,123.628632 
        C61.391621,124.353027 63.701393,125.077415 66.649879,125.993507 
        C67.091537,126.030602 67.533188,126.067688 68.681496,126.469543 
        C77.254219,128.026611 85.777061,128.664505 94.729950,125.073532 
      z"/>
      <path fill="#F1BACA" opacity="1.000000" stroke="none" 
        d="
      M48.782932,118.090378 
        C29.238153,104.967880 21.066750,86.219124 24.383663,63.440609 
        C27.782997,40.096058 41.861622,24.574165 64.579018,17.853977 
        C92.643089,9.552155 122.080925,25.050739 132.224991,52.883228 
        C142.077271,79.915123 129.206284,110.541145 102.955902,122.462936 
        C101.452431,123.145752 99.839676,123.587921 97.659073,124.218834 
        C96.703857,124.500092 96.367340,124.704620 96.030823,124.909149 
        C95.597198,124.963943 95.163574,125.018738 94.101540,124.936226 
        C88.407784,125.203392 83.347412,125.696281 78.275360,125.981491 
        C74.850372,126.174095 71.408928,126.073967 67.974846,126.104782 
        C67.533188,126.067688 67.091537,126.030602 66.153275,125.599823 
        C63.280994,124.512146 60.905327,123.818161 58.529663,123.124176 
        C58.529663,123.124168 58.262390,123.136414 57.894249,122.885193 
        C57.160278,122.434944 56.794453,122.235916 56.428627,122.036896 
        C56.428627,122.036896 56.275555,122.180061 55.918530,121.939377 
        C55.186745,121.475822 54.811985,121.252960 54.437225,121.030106 
        C54.437229,121.030098 54.282436,121.237747 54.021683,120.867325 
        C52.101597,119.694733 50.442265,118.892555 48.782932,118.090378 
      M109.641220,70.834061 
        C103.372070,60.227222 97.153877,49.589836 90.819199,39.022278 
        C84.685135,28.789396 74.515083,28.737892 68.358276,38.978161 
        C60.636353,51.821602 53.028835,64.735321 45.506271,77.696678 
        C39.305122,88.381233 44.444897,97.407143 56.898933,97.734688 
        C63.725479,97.914223 70.560646,97.771324 77.391914,97.764664 
        C86.055786,97.756218 94.731926,98.024460 103.380409,97.652878 
        C114.381897,97.180199 119.300278,88.405273 114.235176,78.828705 
        C112.913025,76.328918 111.427177,73.915710 109.641220,70.834061 
      z"/>
      <path fill="#F7A8D1" opacity="1.000000" stroke="none" 
        d="
      M68.328171,126.287163 
        C71.408928,126.073967 74.850372,126.174095 78.275360,125.981491 
        C83.347412,125.696281 88.407784,125.203392 93.772263,124.945328 
        C85.777061,128.664505 77.254219,128.026611 68.328171,126.287163 
      z"/>
      <path fill="#F7A8D1" opacity="1.000000" stroke="none" 
        d="
      M58.805756,123.376404 
        C60.905327,123.818161 63.280994,124.512146 65.833908,125.503967 
        C63.701393,125.077415 61.391621,124.353027 58.805756,123.376404 
      z"/>
      <path fill="#F7A8D1" opacity="1.000000" stroke="none" 
        d="
      M49.005547,118.346504 
        C50.442265,118.892555 52.101597,119.694733 53.897644,120.830124 
        C52.432297,120.309769 50.830231,119.456200 49.005547,118.346504 
      z"/>
      <path fill="#F7A8D1" opacity="1.000000" stroke="none" 
        d="
      M54.623711,121.320068 
        C54.811985,121.252960 55.186745,121.475822 55.821373,121.900093 
        C55.657562,121.937683 55.233879,121.773857 54.623711,121.320068 
      z"/>
      <path fill="#F7A8D1" opacity="1.000000" stroke="none" 
        d="
      M56.625961,122.298172 
        C56.794453,122.235916 57.160278,122.434944 57.779129,122.817047 
        C57.629196,122.853233 57.226242,122.706345 56.625961,122.298172 
      z"/>
      <path fill="#F7A8D1" opacity="1.000000" stroke="none" 
        d="
      M96.343330,124.876556 
        C96.367340,124.704620 96.703857,124.500092 97.309525,124.170120 
        C97.271065,124.311096 96.963455,124.577530 96.343330,124.876556 
      z"/>
      <path fill="#41313B" opacity="1.000000" stroke="none" 
        d="
      M109.828873,71.148407 
        C111.427177,73.915710 112.913025,76.328918 114.235176,78.828705 
        C119.300278,88.405273 114.381897,97.180199 103.380409,97.652878 
        C94.731926,98.024460 86.055786,97.756218 77.391914,97.764664 
        C70.560646,97.771324 63.725479,97.914223 56.898933,97.734688 
        C44.444897,97.407143 39.305122,88.381233 45.506271,77.696678 
        C53.028835,64.735321 60.636353,51.821602 68.358276,38.978161 
        C74.515083,28.737892 84.685135,28.789396 90.819199,39.022278 
        C97.153877,49.589836 103.372070,60.227222 109.828873,71.148407 
      M101.110458,66.407043 
        C96.113808,57.962543 91.215073,49.458015 86.087387,41.093845 
        C82.437485,35.140221 76.657684,35.052540 73.088493,40.962582 
        C65.093613,54.200859 57.222389,67.516006 49.477097,80.901848 
        C45.825577,87.212601 48.660160,92.421783 55.915150,92.511787 
        C71.716843,92.707802 87.524567,92.691063 103.326279,92.486641 
        C110.300125,92.396423 113.108192,87.347672 109.811180,81.234444 
        C107.208328,76.408295 104.268486,71.763901 101.110458,66.407043 
      z"/>
      <path fill="#DF63AD" opacity="1.000000" stroke="none" 
        d="
      M101.294388,66.722916 
        C104.268486,71.763901 107.208328,76.408295 109.811180,81.234444 
        C113.108192,87.347672 110.300125,92.396423 103.326279,92.486641 
        C87.524567,92.691063 71.716843,92.707802 55.915150,92.511787 
        C48.660160,92.421783 45.825577,87.212601 49.477097,80.901848 
        C57.222389,67.516006 65.093613,54.200859 73.088493,40.962582 
        C76.657684,35.052540 82.437485,35.140221 86.087387,41.093845 
        C91.215073,49.458015 96.113808,57.962543 101.294388,66.722916 
      M76.639168,71.407372 
        C77.473358,72.421577 78.307549,73.435776 79.141739,74.449982 
        C80.075508,73.208557 81.654526,72.049316 81.824997,70.710686 
        C82.391396,66.263245 82.666428,61.746052 82.556923,57.267742 
        C82.528915,56.122532 80.850784,54.399479 79.612061,54.039913 
        C77.497063,53.425976 75.703850,54.206642 75.898735,57.116295 
        C76.199097,61.600746 76.326088,66.096802 76.639168,71.407372 
      M77.126617,77.782356 
        C77.284142,79.716728 77.441673,81.651100 77.599197,83.585472 
        C78.976936,83.462692 80.630585,83.754120 81.641563,83.080711 
        C82.394997,82.578857 82.918564,80.126038 82.497536,79.765846 
        C81.198410,78.654404 79.397491,78.129509 77.126617,77.782356 
      z"/>
      <path fill="#452B3E" opacity="1.000000" stroke="none" 
        d="
      M76.582901,70.997650 
        C76.326088,66.096802 76.199097,61.600746 75.898735,57.116295 
        C75.703850,54.206642 77.497063,53.425976 79.612061,54.039913 
        C80.850784,54.399479 82.528915,56.122532 82.556923,57.267742 
        C82.666428,61.746052 82.391396,66.263245 81.824997,70.710686 
        C81.654526,72.049316 80.075508,73.208557 79.141739,74.449982 
        C78.307549,73.435776 77.473358,72.421577 76.582901,70.997650 
      z"/>
      <path fill="#4D2A44" opacity="1.000000" stroke="none" 
        d="
      M77.459045,77.579575 
        C79.397491,78.129509 81.198410,78.654404 82.497536,79.765846 
        C82.918564,80.126038 82.394997,82.578857 81.641563,83.080711 
        C80.630585,83.754120 78.976936,83.462692 77.599197,83.585472 
        C77.441673,81.651100 77.284142,79.716728 77.459045,77.579575 
      z"/>
  </svg>`;
      }
    } else {
      color = truckColorMapping[marker.status];
      paleColor = truckStatusLightColorMap[marker.status];
      clusteringIdentifier = marker.status;
    }

    // if special case hasn't been handled earlier, set up default marker
    if (!svgContent) {
      svgContent = `<?xml version="1.0" encoding="utf-8"?>
      <svg viewBox="0 0 150 150" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
          <!-- Outer circle -->
          <ellipse style="fill: none; stroke-width: 15; stroke: ${color};" cx="76.913" cy="74.565" rx="35.056" ry="36.967" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
          <!-- Larger dot -->
           <ellipse style="fill: ${color}; stroke-width: 15; stroke: ${paleColor};" cx="76.913" cy="74.565" rx="30.056" ry="31.967" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -7.105427357601002e-15)"/>
      </svg>
  `;
    }

    const markerAnnotation = new mapkit.ImageAnnotation(markerCoord, {
      url: { 1: `data:image/svg+xml;base64,${btoa(svgContent)}` },
      anchorOffset: new DOMPoint(0, 0),
      clusteringIdentifier,
    });
    markerAnnotation.addEventListener('select', () => {
      handleShowMapFlyout(marker);
    });
    markerAnnotation.addEventListener('deselect', () => {
      removeAnnotation();
    });
    markerAnnotations.push(markerAnnotation);
    map.addAnnotation(markerAnnotation);
  };

  const setupMapKitJs = async () => {
    if (!window.mapkit || window.mapkit.loadedLibraries?.length === 0) {
      await new Promise((resolve) => {
        window.initMapKit = resolve;
      });
      delete window.initMapKit;
    }

    const jwt = process.env.REACT_APP_APPLE_MAP_TOKEN;
    window.mapkit.init({
      authorizationCallback: (done) => {
        done(jwt);
      },
      language: 'en',
    });
  };

  const setupMap = () => {
    const mapCenter = new mapkit.Coordinate(-37.2052003, 174.7239819);

    const mapRegion = new mapkit.CoordinateRegion(
      mapCenter,
      new mapkit.CoordinateSpan(0.06, 0.06),
    );

    map = new mapkit.Map('map-container', {
      center: mapCenter,
      mapType: mapkit.Map.MapTypes.Satellite,
      region: mapRegion,
      showsCompass: mapkit.FeatureVisibility.Hidden,
      showsZoomControl: false,
    });
    mapRef.current = map;
    map.cameraZoomRange = new mapkit.CameraZoomRange(100, 8000);
    map.cameraBoundary = mapRegion.toMapRect();
    map.cameraDistance = 3300;
    // needed for the 'x' button to work on the flyout
    map.addEventListener('single-tap', (event) => {
      removeAnnotation();
    });
  };

  useEffect(() => {
    const fetch = async () => {
      await setupMapKitJs();
      mapkit = window.mapkit;
      setupMap();
      setSetupCompleted(true);
    };

    fetch();
    return () => {
      delete window.initMapKit;
    };
  }, []);

  useEffect(() => {
    if (!setupCompleted) return;
    map.isZoomEnabled = true;
    map.isScrollEnabled = true;
  }, [setupCompleted]);

  // add points to map
  useEffect(() => {
    if (!setupCompleted) return;

    const wasFlyoutVisible = currentMarkerFlyout?.visible;
    removeMarkers();
    const coordinatesAndItem = getListOfCoordinates(markers, module);
    if (coordinatesAndItem) {
      coordinatesAndItem.forEach(({ coordinates, item }) => {
        createMarker({ coordinates, marker: item });

        if (showFlyout && selectedItemName && selectedItemName === item.name) {
          // If the selectedItemName has changed, update the flyout displayed on the map
          handleAnnotation({ coordinates, marker: item });
        } else if (
          currentMarkerFlyout?.data?.marker &&
          wasFlyoutVisible &&
          item.name === currentMarkerFlyout.data.marker.name
        ) {
          // if a flyout was open before this update, make sure it stays open
          handleAnnotation({
            coordinates,
            marker: item,
          });
        }
      });
    }
  }, [markers, selectedItemName, showFlyout]);

  useEffect(() => {
    if (!setupCompleted) return;
    removeAnnotation();
    removeMarkers();
    fitScreen();
  }, [module, setupCompleted]);

  const handleZoom = (delta) => {
    let counter = 0;
    const interval = setInterval(() => {
      mapRef.current._impl.zoomLevel += delta;
      counter += 0.05;
      if (counter >= 1) {
        clearInterval(interval);
      }
    }, 20);
  };

  const fitScreen = () => {
    const mapCenter = new mapkit.Coordinate(-37.204, 174.724);

    map.setCenterAnimated(mapCenter);

    mapRef.current._impl.zoomLevel = 15.3;
  };

  return (
    <>
      <section
        id="map-container"
        style={{ height: '100%', width: '100%', zIndex: 5 }}
      />

      <div
        style={{
          bottom: '0rem',
          marginBottom: '2rem',
          position: 'absolute',
          zIndex: 6,
          borderRadius: '4px ',
          right: '1rem',
          backgroundColor: 'white',
        }}
      >
        <div
          className="d-flex align-items-center justify-content-center cursor-pointer p-2"
          onClick={() => handleZoom(0.06)}
        >
          <img
            src={plusIcon}
            alt="Zoom In"
            style={{ width: '1.5rem', height: '1.5rem' }}
          />
        </div>
        <div
          className="d-flex align-items-center justify-content-center cursor-pointer p-2"
          onClick={() => handleZoom(-0.06)}
        >
          <img
            src={minusIcon}
            alt="Zoom Out"
            style={{ width: '1rem', height: '1rem' }}
          />
        </div>
      </div>
      <div
        style={{
          bottom: '0rem',
          marginBottom: '2rem',
          position: 'absolute',
          zIndex: 6,
          right: '4.1rem',
          backgroundColor: 'white',
          borderRadius: '4px ',
        }}
      >
        <div
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '2rem',
          }}
          onClick={(e) => {
            e.preventDefault();
            fitScreen();
          }}
        >
          <div style={{ fontSize: '0.8rem' }} className="p-1 px-2">
            Fit Screen
          </div>
        </div>
      </div>
      {showTrackBinModal && selectedMarkerData && (
        <TrackBinModal
          bin={module === MODULES.BINS ? selectedMarkerData : null}
          truck={module === MODULES.TRUCKS ? selectedMarkerData : null}
          isOpen={showTrackBinModal}
          onClose={closeTrackBinModal}
          binName={
            module === MODULES.BINS
              ? selectedMarkerData?.name
              : selectedMarkerData.bin
          }
        />
      )}
    </>
  );
};
