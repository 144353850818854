import React, { useEffect, useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import { AiFillDelete } from 'react-icons/ai';
import upload from './../../assets/images/upload.svg';
import './index.css';

const minPoints = 4;

const PointsTable = ({
  points,
  onChange,
  onSelectRow,
  selectedRow,
  isEditing,
}) => {
  const [data, setData] = useState(points);
  const inputRef = useRef(null);

  useEffect(() => {
    let pointsArray = points;
    while (pointsArray.length < minPoints) {
      pointsArray.push({ latitude: '0.000000', longitude: '0.000000' });
    }
    setData(pointsArray);
  }, [points]);

  useEffect(() => {
    if (points?.length > minPoints) onChange(data);
  }, [data]);

  const handleLatitudeChange = (index, value) => {
    const newData = [...data];
    const parsedValue = parseFloat(value);
    newData[index].latitude =
      parsedValue.toString().length - parsedValue.toString().indexOf('.') > 7
        ? parsedValue.toFixed(6)
        : value;
    setData(newData);
  };

  const handleLongitudeChange = (index, value) => {
    const newData = [...data];
    const parsedValue = parseFloat(value);
    newData[index].longitude =
      parsedValue.toString().length - parsedValue.toString().indexOf('.') > 7
        ? parsedValue.toFixed(6)
        : value;
    setData(newData);
  };

  const handleAddPoint = () => {
    const newData = [...data, { latitude: '0.000000', longitude: '0.000000' }];
    setData(newData);
  };

  const handleDeleteRow = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
    isEditing();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const contents = event.target.result;
        const lines = contents.split('\n');
        const parsedData = [];
        for (let i = 1; i < lines.length; i++) {
          const [latitude, longitude] = lines[i].split(',');
          if (latitude && longitude) {
            parsedData.push({
              latitude: parseFloat(latitude).toFixed(6),
              longitude: parseFloat(longitude).toFixed(6),
            });
          }
        }
        onChange(parsedData);
        setData(parsedData);
      };
      reader.readAsText(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        accept=".csv"
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={handleFileUpload}
      />

      <button
        className="btn save  d-flex justify-content-center align-items-center text-white"
        onClick={() => {
          inputRef.current.click();
        }}
        style={{
          height: '2.3rem',
          width: '9.2rem',
          font: 'normal normal bold Open Sans',
          border: ' 1px solid black',
          background: '#2B2D2F 0% 0% no-repeat padding-box',
          borderRadius: 4,
        }}
      >
        <Image
          src={upload}
          className="mx-2"
          style={{ height: '0.8rem', width: '0.8rem' }}
        />
        <span style={{ fontSize: '0.8rem' }}> Upload CSV</span>{' '}
      </button>

      <table className="mm-points-table">
        <thead>
          <tr>
            <th style={{ width: '5rem' }}></th>
            <th style={{ width: '12rem' }}>Latitude</th>
            <th style={{ width: '12rem' }}>Longitude</th>
          </tr>
        </thead>
        <tbody>
          {data.map((point, index) => (
            <tr key={index}>
              <td>
                <strong>POINT {index + 1} </strong>
              </td>
              <td
                colspan="2"
                onClick={() => onSelectRow(index)}
                className="m-0 p-0"
              >
                <div
                  style={{
                    border:
                      index == selectedRow
                        ? '1px solid #05795F'
                        : '1px solid #EBEBEB',
                    borderTopLeftRadius: index == 0 ? '5px' : '0px',
                    borderBottomLeftRadius:
                      index == data.length - 1 ? '5px' : '0px',
                    borderTopRightRadius: index == 0 ? '5px' : '0px',
                    borderBottomRightRadius:
                      index == data.length - 1 ? '5px' : '0px',
                  }}
                >
                  <input
                    type="number"
                    step="0.000001"
                    className="m-0 p-0 px-2"
                    value={point.latitude}
                    onChange={(e) =>
                      handleLatitudeChange(index, e.target.value)
                    }
                    style={{
                      borderTopLeftRadius: index == 0 ? '5px' : '0px',
                      borderBottomLeftRadius:
                        index == data.length - 1 ? '5px' : '0px',
                      border: '0px',
                      width: '50%',
                      backgroundColor: index == selectedRow ? '#05795F1A' : '',
                    }}
                  />
                  <input
                    type="number"
                    step="0.000001"
                    className="m-0 p-0 px-2"
                    style={{
                      borderTopRightRadius: index == 0 ? '5px' : '0px',
                      borderBottomRightRadius:
                        index == data.length - 1 ? '5px' : '0px',
                      border: '0px',
                      borderLeft: '1px solid #EBEBEB',
                      width: '50%',
                      backgroundColor: index == selectedRow ? '#05795F1A' : '',
                    }}
                    value={point.longitude}
                    onChange={(e) =>
                      handleLongitudeChange(index, e.target.value)
                    }
                  />
                </div>
              </td>
              <td
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteRow(index);
                }}
              >
                {data?.length > minPoints && <AiFillDelete />}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-2 mb-3">
        <div
          className="m-0"
          style={{
            fontSize: '0.8rem',
            lineHeight: '1.5',
            color: '#707070',
          }}
        >
          Follow this format : <br /> 60.000000
        </div>
      </div>

      <button
        className="btn save btn-focus d-flex justify-content-center align-items-center "
        onClick={() => {
          handleAddPoint();
        }}
        style={{
          height: '2.3rem',
          font: 'normal normal bold Open Sans',
          border: ' 1px solid black',
          borderRadius: 4,
          background: 'white 0% 0% no-repeat padding-box',
          width: '9.2rem',
          color: '#2B2D2F',
          marginBottom: '20px',
          fontSize: '0.8rem',
        }}
      >
        Add Point
      </button>
    </div>
  );
};

export default PointsTable;
