import { FC, ReactNode } from 'react';
import './styles.css';
import downloadIcon from '../../assets/images/download.svg';

type ModalProps = {
  isOpen: boolean;
  header?: ReactNode;
  onClose: () => void;
  downloadIcon?: boolean;
  children: ReactNode;
  headerRightChild?: ReactNode;
  removeHeaderMargin?: boolean;
  width?: string;
  flyout?: boolean;
};
const PopupModal: FC<ModalProps> = (props) => {
  if (!props.isOpen) return null;
  return (
    <div
      className="popUp-container"
      style={props.flyout ? { backgroundColor: 'transparent' } : {}}
    >
      <div
        className={`bg-light popup-container rounded p-4 flex justify-content-evenly ${
          props.flyout ? 'positionFix' : ''
        }`}
        style={props.width ? { width: props.width } : {}}
      >
        <div className="d-flex justify-content-between flex-row h-auto ">
          <div className="fw-bold header">{props.header ?? null}</div>
          <div className="d-flex align-align-items-center right-child">
            {props.headerRightChild}
            {props.downloadIcon && (
              <img
                height={22}
                width={22}
                className="download-icon"
                src={downloadIcon}
                alt="Download Icon"
              />
            )}
            <button
              className={`frameless-button ${
                props.removeHeaderMargin ? '' : 'mb-3'
              }`}
              onClick={props.onClose}
            >
              <div>X</div>
            </button>
          </div>
        </div>
        <div
          className="text-center d-flex flex-column"
          style={{ fontSize: '14px' }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default PopupModal;
