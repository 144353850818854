import { Reports } from '@optitrac/components';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../service/userContext';
import { Department } from './report.type';
import { toast } from 'react-toastify';
import { Asset, GroupedAsset } from '../../../utils/types';
import { fetch as customFetch } from '../../../utils/api';
import { API_METHODS } from '../../../dustControl/utils/constants';
import { Endpoint } from '../../../materialMovements/pages/shiftLoader/helper';
import { useNavigate } from 'react-router-dom';
import { groupAssetsByCategory } from '../../../utils/helper';

const ReportsPage = () => {
  const navigate = useNavigate();
  const [assetData, setAssetData] = useState<GroupedAsset[]>([]);
  const { departments } = useContext(UserContext);
  const departmentOption = departments.map((item: Department) => ({
    name: item.name,
    value: String(item.code),
  }));

  useEffect(() => {
    const fetchAllTrucks = async (): Promise<void> => {
      try {
        const response = await customFetch<Asset[]>({
          method: API_METHODS.GET,
          endPoint: Endpoint.getTrucks,
          navigateToLoginPage: () => navigate('/'),
        });

        const assetResponse: Asset[] = response.data;
        const assets = groupAssetsByCategory(assetResponse);
        setAssetData(assets);
      } catch (error: any) {
        toast.error(error?.message);
      }
    };

    fetchAllTrucks();
  }, []);

  return <Reports departmentData={departmentOption} assetData={assetData} />;
};

export default ReportsPage;
