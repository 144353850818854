import { Dispatch, SetStateAction } from 'react';
import {
  BinHistory,
  BinPickup,
  GetAllTrucksData,
  PickupStatus,
  PickupType,
} from '../../utils/types';
import {
  BinHistoryFlyerSection,
  TruckHistoryFlyerSection,
} from '../../utils/constants';
import { PieChartProps } from '../PieChart/types';

export type HistoryProps = {
  trucks: GetAllTrucksData;
};

export type HistoryFlyerProps = {
  showHistoryFlyer: boolean;
  setShowHistoryFlyer: (showHistoryFlyer: boolean) => void;
  historyCategory: HistoryCategory;
  binHistories?: BinHistory[];
  date: DateRange;
};

export type HistoryFlyerComponent = {
  title: BinHistoryFlyerSection | TruckHistoryFlyerSection;
  chartComponentProps: PieChartProps;
};

export type HistoryTableProps = {
  loading: boolean;
  data: BinHistory[];
  historyCategory: HistoryCategory;
  trucks: GetAllTrucksData;
  onDownloadClick: () => void;
};

export type DateRange = { from: string | Date; to: string | Date };

export type FilterSubheaderProps = {
  selectedSection: HistoryCategory;
  setSelectedSection: Dispatch<SetStateAction<HistoryCategory>>;
  dateRange: DateRange;
  setSelectedDateRange: Dispatch<SetStateAction<DateRange>>;
  onSearchChange: Dispatch<SetStateAction<string>>;
  onDownloadClick: () => void;
  selectedFilters: SelectedFilters;
  setSelectedFilters: Dispatch<SetStateAction<SelectedFilters>>;
  binHistories: BinHistory[];
};

export type FilterModalProps = {
  dateRange: DateRange | undefined;
  modalOpen: boolean;
  modalToggle: Dispatch<SetStateAction<boolean>>;
  setFilterModalDateRange: Dispatch<SetStateAction<DateRange | undefined>>;
  filterModalDateRange: DateRange | undefined;
  setDateRange: Dispatch<SetStateAction<DateRange | undefined>>;
  onApplyDateRange: () => void;
  selectedFilters: SelectedFilters;
  setSelectedFilters: Dispatch<SetStateAction<SelectedFilters>>;
  filterFields: FilterField[];
};

export type FilterField = {
  name: string;
  key: FilterSections;
  options: { value: string; name: string; color?: string }[];
  hideSearchBar: boolean;
};

export enum HistoryCategory {
  BINS,
  TRUCKS,
}

export type HistoriesFilterTimePeriod = {
  startDate: Date;
  endDate: Date;
};

export type BinLoad = {
  binName: string;
  scrapType: string;
  binPickups: BinPickup[];
};

export type SelectedFilters = {
  [filterGroup in FilterSections]: string[];
};

export enum FilterSections {
  bin = 'bin',
  truckName = 'truckName',
  status = 'status',
  type = 'type',
}

export type OnCheckboxClickParams = {
  fieldKey: keyof SelectedFilters;
  optionValue: string;
  multiselect: boolean;
};

export type BinPickupJob = {
  key: PickupType;
  value: number;
  colors?: string;
};

export type JobStatus = {
  key: PickupStatus;
  value: number;
  color?: string;
};

export enum JobTypeFilters {
  'Scheduled' = 'Scheduled',
  'Unscheduled' = 'Unscheduled',
  'ScheduledNoDump' = 'Scheduled (Dump Not Required)',
  'UnscheduledNoDump' = 'Unscheduled (Dump Not Required)',
}
