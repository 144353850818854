import React from 'react';
import './index.css';

const Button = ({ onClick, key, style, title, active, icon }) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <div
      key={key}
      className={`d-flex align-items-center justify-content-center ${
        active ? 'live-active' : 'live-inactive'
      } assets-button `}
      onClick={handleClick}
      style={{
        marginTop: '2.2rem',
        font: '  normal normal 0.8rem Open Sans',
        height: '2.1rem',
        minWidth: '10rem',
        padding: ' 0rem 0.7rem',
        boxShadow: '0px 0.25rem 0.33rem #00000029',
        borderRadius: '0.25rem',
        marginRight: '0.35rem',
        border: '0.1rem solid #FFFFFF',
        cursor: 'pointer',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        ...style,
      }}
    >
      <div
        style={{
          fontWeight: active ? 'bold' : 'normal',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {icon}
        {title}
      </div>
    </div>
  );
};
export default Button;
