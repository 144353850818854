import { useState, useContext } from 'react';
import PopupModal from '../popUp';
import { UpdateHazardsModalProps } from './types';
import CustomButton from '../../../dustControl/components/CustomButton';
import * as toast from '../../../dustControl/components/toast';
import { UserContext } from '../../../pages/service/userContext';
import { API_METHODS } from '../../utils/constants';
import { Routes } from '../../utils/routes';
import TextArea from './TextArea';
import { useScrapBins } from '../../context/scranBinsContext';
import { BinWithPickup } from '../../utils/types';

export const UpdateHazardsModal: React.FC<UpdateHazardsModalProps> = ({
  isOpen,
  onClose,
  bin,
}) => {
  const [hazard, setHazard] = useState(bin?.hazards);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { ApiHandler } = useContext(UserContext);
  const { setBins } = useScrapBins();
  const isHazardTextUnchanged = (): boolean => {
    return hazard === bin?.hazards;
  };

  const updateHazard = async () => {
    try {
      setIsLoading(true);
      const response = await ApiHandler({
        reqParam: {
          hazards: hazard,
          binName: bin.name,
        },
        method: API_METHODS.PATCH,
        endPoint: Routes.UPDATE_BIN,
      });
      const updatedBin: BinWithPickup = response.data;
      setBins((prevBins) =>
        prevBins.map((bin: BinWithPickup) =>
          bin.name === updatedBin.name ? updatedBin : bin,
        ),
      );
      onClose();
      toast.success(`Hazards successfully updated`);
    } catch (err) {
      toast.error((err as Error)?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const title: string = `${bin.hazards ? 'Update ' : 'Add'} Hazards - Bin ${
    bin.name
  }`;

  if (!bin) return null;
  else
    return (
      <PopupModal isOpen={isOpen} onClose={onClose} header={title}>
        <div className="d-flex flex-column justify-content-between h-100">
          <div className="d-flex flex-column flex-1">
            <TextArea
              value={hazard}
              placeholder={'Tap to type your hazards'}
              numOfLines={20}
              onChange={(newHazard: string) => {
                setHazard(newHazard);
              }}
            />
          </div>

          <div className="mt-3">
            <CustomButton
              disabled={isHazardTextUnchanged()}
              style={{
                background: 'black',
                paddingLeft: '2rem',
                paddingRight: '2rem',
                width: '100%',
                alignSelf: 'center',
              }}
              loading={isLoading}
              id="updateHazard"
              onClick={isHazardTextUnchanged() ? null : updateHazard}
              title={`Save ${bin.hazards ? ' Changes ' : ''}`}
            />
          </div>
        </div>
      </PopupModal>
    );
};
