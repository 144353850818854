import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  API_METHODS,
  ENDPOINTS,
  SOCKETS,
  ZONE_DATA,
} from '../../utils/constants';
import { SocketContext } from '../../utils/socket';
import PieChartGraph from '../PieChart';
import { UserContext } from '../../../pages/service/userContext';
import * as toast from '../../components/toast';

const WaterWidget = () => {
  const { ApiHandler } = useContext(UserContext);
  const { socket } = useContext(SocketContext);

  let [chartData, setChartData] = useState([]);
  let [totalWaterUsage, setTotalWaterUsage] = useState(0);

  const checkIfDataExists = (data) => {
    return data?.some((usage) => usage > 0);
  };

  const mapWaterData = useCallback((data) => {
    if (data && data.totalWaterUsage) {
      setTotalWaterUsage(data.totalWaterUsage);
    }

    let zoneData = [];
    Object.entries(ZONE_DATA)?.forEach(([key, zone]) => {
      if (data[zone?.name]) {
        zoneData.push(data[zone.name]);
      } else {
        zoneData.push(0);
      }
    });

    if (checkIfDataExists(zoneData)) {
      setChartData(zoneData);
    } else {
      setChartData([]);
    }
  }, []);

  useEffect(() => {
    const fetchWaterConsumption = async () => {
      try {
        let response = await ApiHandler({
          reqParam: {},
          endPoint: ENDPOINTS.getWaterConsumption,
          method: API_METHODS.GET,
        });

        mapWaterData(response.data);
      } catch (error) {
        toast.error(error?.message);
      }
    };

    socket.on(SOCKETS.water, (data) => {
      if (data) {
        mapWaterData(data);
      }
    });

    fetchWaterConsumption();
    return () => {
      socket.off(SOCKETS.water);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="widget-container rounded-2 mt-5">
      <div className="header">
        <div>Water Consumption</div>
        <div className="total">
          <span>Total:</span> {totalWaterUsage.toLocaleString('en-US')} litres
        </div>
      </div>
      <div className="body">
        <div className="pie-container">
          <PieChartGraph data={chartData} />
        </div>
        <div className="zone-container">
          <div>
            {Object.keys(ZONE_DATA).map((data) => {
              return (
                <span key={data}>
                  <div>
                    <div
                      className="circle"
                      style={{ backgroundColor: ZONE_DATA[data].color }}
                    ></div>{' '}
                    {ZONE_DATA[data].label}
                  </div>
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaterWidget;
