import { range, forEach, isEmpty } from 'lodash';
import { PieChartLegendData } from './types';

/*
 The colours need be dynamically generated because this is also used by the 
 history flyer, and the history flyer component needs to show data for all bins, 
 and it will make it easy to dynamically generate the number of colours based on the 
 number of data provided
*/
export const generateDistinctColours = (
  numberOfColoursRequired: number,
  legendData: PieChartLegendData[],
): string[] => {
  const defaultColours = ['#47519D', '#54BA9A', '#E09C2F', '#AC4694'];
  const colours: string[] = [];
  if (numberOfColoursRequired <= 4) {
    const passedColors: string[] = legendData.reduce(
      (accum: string[], { color }) => {
        if (color) {
          accum.push(color);
        }
        return accum;
      },
      [],
    );

    isEmpty(passedColors)
      ? colours.push(...defaultColours)
      : colours.push(...passedColors);
  } else {
    forEach(range(numberOfColoursRequired), (index: number) => {
      const hue: number = Math.round((360 / numberOfColoursRequired) * index);
      const saturation: number = 50;
      const lightness: number = 60;
      const colour: string = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
      colours.push(colour);
    });
  }
  return colours;
};
