import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const CustomButton = (props) => {
  const { title, style, onClick, loading, id, disabled } = props;
  return (
    <div
      type="button"
      id={id}
      style={{
        ...style,
        background: disabled && '#B1B1B1',
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
      className="custom-button"
      onClick={onClick}
    >
      {!loading && <p className="btn-text text-white fw-bold">{title}</p>}

      <ThreeDots
        height={20}
        radius={20}
        color="#fff"
        ariaLabel="three-dots-loading"
        wrapperClassName="p-0 m-0"
        visible={loading}
      />
    </div>
  );
};

export default CustomButton;
