import { FC } from 'react';
import CustomButton from '../../dustControl/components/CustomButton';
import PopupModal from '../../scrapBins/components/popUp';

type DownLoadProps = {
  isOpen: boolean;
  onClose: () => void;
};
const DownLoadModal: FC<DownLoadProps> = (props) => {
  return (
    <PopupModal isOpen={props.isOpen} onClose={props.onClose}>
      <div className="m-5">Download functionality coming soon</div>
      <CustomButton
        id="downloadButton"
        style={{
          background: 'black',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          width: 'fit-content',
          alignSelf: 'center',
        }}
        loading={false}
        title={'OK'}
        onClick={props.onClose}
      />
    </PopupModal>
  );
};

export default DownLoadModal;
