import { useContext, useEffect, useState } from 'react';
import { withAccessControl } from '../service/with-access-control';
import {
  DatePicker,
  TimePicker,
  BinsCard,
  DustControlCard,
  ParkedTimeCard,
  SpeedingEventsCard,
  ShiftLoaderCard,
  MaterialMovementsCard,
  PreStartChecksCard,
  KPI,
} from '@optitrac/components';
import { getAccessToken } from '../service/accessToken';
import AssetTracking from '../assets/AssetTracking.svg';
import materialMovements from '../assets/materialMovements.svg';
import DustControl from '../assets/DustControl.svg';
import PreStartChecks from '../assets/PreStartChecks.svg';
import ScrapBins from '../assets/ScrapBins.svg';
import { UserContext } from '../service/userContext';
import styles from './kpi.module.css';
import { fetch as customFetch } from '../../utils/api';
import { useNavigate } from 'react-router-dom';
import {
  getInitialDateRange,
  groupAssetsByCategory,
  parseTimeToDate,
} from '../../utils/helper';
import {
  BinPickJobColors,
  MaterialColors,
  MaterialDumpingCycleColors,
  preStartChecksColors,
  ShiftLoaderColors,
  SpeedEventColors,
  TowerColors,
  truckColors,
  zoneColors,
} from '../../utils/constants';
import {
  Asset,
  DateRange,
  FinalDateRange,
  GroupedAsset,
  KPIDashboardPageProps,
  License,
} from './types';
import { toast } from 'react-toastify';
import {
  Endpoint,
  getUrl,
} from '../../materialMovements/pages/shiftLoader/helper';
import { API_METHODS } from '../../dustControl/utils/constants';
import ReportsPage from './reports';
import { API } from '../service/apiHandler';
import { subSections } from '../../dustControl/utils/constants';

const KPIDashboard: React.FC<KPIDashboardPageProps> = (
  props: KPIDashboardPageProps,
) => {
  const [dateRange, setDateRange] = useState<DateRange>({
    from: new Date(new Date().setHours(0, 0, 0, 0)),
    to: undefined,
  });
  const [finalDateRange, setFinalDateRange] =
    useState<FinalDateRange>(getInitialDateRange);
  const [fromTime, setFromTime] = useState<string>('');
  const [untilTime, setUntilTime] = useState<string>('');
  const [trucks, setTrucks] = useState<GroupedAsset[]>([]);
  const { licenses }: { licenses: License[] } = useContext(UserContext);
  const navigate = useNavigate();

  const handleNavigate = (): void => navigate('/');

  const handleDateTimeChange = (
    newDateRange: DateRange,
    newFromTime: string,
    newUntilTime: string,
  ) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const fromDate = newDateRange?.from
      ? new Date(newDateRange.from)
      : new Date(today);

    fromDate.setHours(0, 0, 0, 0);

    let toDate: Date;
    if (newDateRange?.to) {
      toDate = new Date(newDateRange.to);
    } else {
      // If newDateRange.to is null, set toDate to new Date(today) if newDateRange.from is today's date
      if (
        newDateRange?.from &&
        new Date(newDateRange.from).toISOString() === today.toISOString()
      ) {
        toDate = new Date(today);
      } else {
        // Otherwise, set toDate to new Date(newDateRange.from) or today's date if undefined
        toDate = new Date(newDateRange?.from || today);
      }
    }

    toDate.setHours(23, 59, 59, 999);

    const startTimestamp: number = newFromTime
      ? Math.floor(parseTimeToDate(newFromTime, fromDate).getTime() / 1000)
      : Math.floor(fromDate.getTime() / 1000);
    const endTimestamp: number = newUntilTime
      ? Math.floor(parseTimeToDate(newUntilTime, toDate).getTime() / 1000)
      : Math.floor(toDate.getTime() / 1000);

    setFinalDateRange({ startTimestamp, endTimestamp });
  };

  useEffect(() => {
    const fetchAllTrucks = async (): Promise<void> => {
      try {
        const response = await customFetch<Asset[]>({
          method: API_METHODS.GET,
          endPoint: Endpoint.getTrucks,
          navigateToLoginPage: () => navigate('/'),
        });

        const trucksResponse: Asset[] = response.data;
        const trucks = groupAssetsByCategory(trucksResponse);
        setTrucks(trucks);
      } catch (error: any) {
        toast.error(error?.message);
      }
    };

    fetchAllTrucks();
  }, []);
  return (
    <div className="p-3">
      {props.selectedTab === 6 ? (
        <KPI
          section={subSections[500][props.selectedTab]}
          getAccessToken={getAccessToken}
          UserContext={UserContext}
          API={API}
        />
      ) : props.selectedTab === 7 ? (
        <ReportsPage />
      ) : (
        <>
          <div className="d-flex justify-content-start gap-3">
            <DatePicker
              finalRange={dateRange}
              handleApply={(v: DateRange) => {
                setDateRange(v);
                handleDateTimeChange(v, fromTime, untilTime);
              }}
              isDisabled={false}
            />
            <div className={styles.timeFilter}>
              <TimePicker
                format="12-hour"
                selectedTime={fromTime}
                onTimeChange={(time: string) => {
                  setFromTime(time);
                  handleDateTimeChange(dateRange, time, untilTime);
                }}
                placeholder="From"
                minTime="12:00 AM"
                maxTime="11:59 PM"
              />
              <span>—</span>
              <TimePicker
                format="12-hour"
                selectedTime={untilTime}
                onTimeChange={(time: string) => {
                  setUntilTime(time);
                  handleDateTimeChange(dateRange, fromTime, time);
                }}
                placeholder="Until"
                minTime="12:00 AM"
                maxTime="11:59 PM"
              />
            </div>
          </div>
          <div className=" mt-4 d-flex justify-content-start flex-column gap-3 flex-wrap align-items-center">
            <div className="d-flex gap-3  flex-wrap w-100">
              <SpeedingEventsCard
                finalDateRange={finalDateRange}
                licenses={licenses.map(({ name, code }) => ({ name, code }))}
                headerImageComponent={
                  <img src={AssetTracking} alt="icon" height={25} />
                }
                fetch={customFetch}
                className={`${
                  props.sidebarActive
                    ? styles.speedingCard
                    : styles.expanedSpeedingCard
                }`}
                colors={SpeedEventColors}
                navigateToLoginPage={handleNavigate}
                endPoint={getUrl(Endpoint.getSpeedEvents)}
                trucks={trucks}
              />
              <ParkedTimeCard
                isExpanded={!props.sidebarActive}
                trucks={trucks}
                finalDateRange={finalDateRange}
                headerImageComponent={
                  <img src={AssetTracking} alt="icon" height={25} />
                }
                licenses={licenses.map(({ name, code }) => ({ name, code }))}
                fetch={customFetch}
                className={`${
                  props.sidebarActive
                    ? styles.parkedTimeCard
                    : styles.expanedParkedTimeCard
                }`}
                colors={truckColors}
                navigateToLoginPage={handleNavigate}
                parkedTimeEndPoint={getUrl(Endpoint.getEngineHours)}
                GeonZoneEndpoint={getUrl(Endpoint.getParkedTimeByZones)}
              />
            </div>
            <div className="d-flex gap-3  flex-wrap w-100">
              <BinsCard
                isExpanded={props.sidebarActive}
                finalDateRange={finalDateRange}
                headerImageComponent={
                  <img src={ScrapBins} alt="icon" height={25} />
                }
                fetch={customFetch}
                className={`${styles.binsCard}`}
                colors={BinPickJobColors}
                navigateToLoginPage={handleNavigate}
                endPoint={getUrl(Endpoint.getBinsPickupCount)}
              />
              <DustControlCard
                finalDateRange={finalDateRange}
                headerImageComponent={
                  <img src={DustControl} alt="icon" height={25} />
                }
                fetch={customFetch}
                className={`${styles.dustControlCard}`}
                zoneColors={zoneColors}
                towerColors={TowerColors}
                navigateToLoginPage={handleNavigate}
                waterComsumptionEndPoint={getUrl(Endpoint.getWaterConsumptions)}
                waterInEndPoint={getUrl(Endpoint.getWaterIn)}
                waterOutEndPoint={Endpoint.getWaterOut}
              />
            </div>
            <div className="d-flex gap-3  flex-wrap w-100">
              <MaterialMovementsCard
                finalDateRange={finalDateRange}
                isExpanded={props.sidebarActive}
                headerImageComponent={
                  <img src={materialMovements} alt="icon" height={25} />
                }
                fetch={customFetch}
                className={`${styles.materialMovementsCard}`}
                chartClass={`${
                  !props.sidebarActive && styles.materialMovementChart
                }`}
                detailClass={`${
                  !props.sidebarActive && styles.materialMovementDetail
                }`}
                doughnutColors={MaterialDumpingCycleColors}
                barChartColors={MaterialColors}
                navigateToLoginPage={handleNavigate}
                endPoint={Endpoint.getMaterialMovementLoads}
                trucks={trucks}
              />
              <ShiftLoaderCard
                finalDateRange={finalDateRange}
                headerImageComponent={
                  <img src={materialMovements} alt="icon" height={25} />
                }
                fetch={customFetch}
                className={`${styles.shiftLoaderCard}`}
                colors={ShiftLoaderColors}
                navigateToLoginPage={handleNavigate}
                timeLineEndPoint={getUrl(Endpoint.getShiftLoaderTimelines)}
                loadEndPoint={getUrl(Endpoint.getShiftLoaderLoads)}
              />
              <PreStartChecksCard
                finalDateRange={finalDateRange}
                headerImageComponent={
                  <img src={PreStartChecks} alt="icon" height={25} />
                }
                fetch={customFetch}
                className={`${styles.preStartChecksCard}`}
                colors={preStartChecksColors}
                navigateToLoginPage={handleNavigate}
                endPoint={getUrl(Endpoint.getPreStartChecks)}
                trucks={trucks}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withAccessControl(KPIDashboard);
