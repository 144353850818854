import React from 'react';
import { GoDotFill } from 'react-icons/go';

import KeyValueLabels from './KeyValueLabels';
import './styles.scss';
import { JobDetailProps } from './types.js';
import LabelWithLine from './LabelWithLine.jsx';
import { PickupStatus } from '../../utils/types';
import { getJobSummaryFields } from './helper';

export const getPickupStatusWithDot = (status: PickupStatus) => {
  let statusString = 'Incomplete';
  let color = '#3E97FF';
  switch (status) {
    default:
    case PickupStatus.INCOMPLETE:
      color = '#D54A4A';
      statusString = 'Incomplete';
      break;
    case PickupStatus.COMPLETE:
      color = '#21A865';
      statusString = 'Complete';
      break;

    case PickupStatus.IN_PROGRESS:
      color = '#3E97FF';
      statusString = 'In Progress';
      break;
  }

  return (
    <div className="d-flex">
      <GoDotFill size={14} className="me-1" color={color} />
      <span className="bin_history_status-text">{statusString}</span>
    </div>
  );
};

const JobDetails: React.FC<JobDetailProps> = ({ pickup, isTruckSection }) => {
  if (!pickup) return null;
  return (
    <div className="m-1">
      <LabelWithLine title={'Job Summary'} />
      <div className="mt-3">
        {getJobSummaryFields(pickup, isTruckSection).map(
          ({ title, value, subvalue }) => (
            <KeyValueLabels
              key={title}
              title={title}
              value={value}
              subValue={subvalue}
            />
          ),
        )}
      </div>
    </div>
  );
};

export default JobDetails;
