import { FunctionComponent } from 'react';

type OperatorProps = {
  height: number;
  width: number;
};

export const Operator: FunctionComponent<OperatorProps> = (
  props: OperatorProps,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Icons"
    viewBox="0 0 60 60"
    {...props}
  >
    <path d="M16.575,40.326A9.984,9.984,0,0,0,9,50.027V57a3,3,0,0,0,3,3H48a3,3,0,0,0,3-3V50.027a9.984,9.984,0,0,0-7.575-9.7L35,38.22v-.842c2.656-1.427,5.3-3.624,6.185-5.91a15.493,15.493,0,0,0,.741-3.576A4.006,4.006,0,0,0,45,24a3.965,3.965,0,0,0-.579-2.043A2.994,2.994,0,0,0,47,19V17a2,2,0,0,0-2-2V14A12,12,0,0,0,35.931,2.358,3,3,0,0,0,33,0H27a3,3,0,0,0-2.931,2.358A12,12,0,0,0,15,14v1a2,2,0,0,0-2,2v2a2.994,2.994,0,0,0,2.579,2.957A3.965,3.965,0,0,0,15,24a4.006,4.006,0,0,0,3.074,3.892,15.54,15.54,0,0,0,.74,3.576c.888,2.286,3.53,4.484,6.186,5.91v.842ZM30,44a5,5,0,0,0,4.827-3.763l3.436.859-1,7.034a1.007,1.007,0,0,1-.992.87H23.73a1,1,0,0,1-.99-.86l-1-7.044,3.436-.859A5,5,0,0,0,30,44ZM42,58H18V42.031l1.786-.447.976,6.847A2.993,2.993,0,0,0,23.73,51H36.27a3,3,0,0,0,2.97-2.579l.974-6.837L42,42.031ZM11,57V50.027a7.981,7.981,0,0,1,5-7.41V58H12A1,1,0,0,1,11,57Zm38-6.973V57a1,1,0,0,1-1,1H44V42.617A7.981,7.981,0,0,1,49,50.027ZM17,14a9.991,9.991,0,0,1,7-9.536V10a1,1,0,0,0,2,0V3a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v7a1,1,0,0,0,2,0V4.464A9.991,9.991,0,0,1,43,14v1H17Zm-2,5V17H45v2a1,1,0,0,1-1,1H16A1,1,0,0,1,15,19Zm5.679,11.743a16.445,16.445,0,0,1-.681-3.8A1,1,0,0,0,19,26a2,2,0,0,1,0-4H41a2,2,0,0,1,0,4,1,1,0,0,0-1,.938,16.41,16.41,0,0,1-.682,3.805C38.11,33.859,32.166,37,30,37S21.89,33.859,20.679,30.743ZM30,39a8.9,8.9,0,0,0,3-.693V39a3,3,0,0,1-6,0v-.693A8.9,8.9,0,0,0,30,39Z" />
  </svg>
);
