import { RiRadioButtonLine } from 'react-icons/ri';
import { useEffect, useMemo } from 'react';
import { buildTimelineArray } from './helper';
import { getFormattedTime } from '../../utils/helpers';

const DOT_HEIGHT = 25;
const LINE_HEIGHT = 30;

export const Dot = ({ Dotstyles, status }) => {
  const getColor = () => {
    if (status == 'completed') return '#1b499f';
    if (status == 'upcoming') return '#939292';
    if (status == 'disconnected') return '#D54A4A';
    if (status == 'current') return '#2A7B39';
    return '#939292';
  };
  const getBgColor = () => {
    if (status == 'completed') return '#72A2FD';
    if (status == 'upcoming') return '#E2E2E2';
    if (status == 'disconnected') return '#FC8A8A';
    if (status == 'current') return '#63E07A';
    return '#E2E2E2';
  };
  return (
    <RiRadioButtonLine
      color={getColor()}
      min={DOT_HEIGHT}
      size={DOT_HEIGHT}
      style={{
        backgroundColor: getBgColor(),
        borderRadius: '50%',
        // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        // padding: '0',
        margin: '0 auto',
        padding: '0px',
        ...(Dotstyles ? Dotstyles : {}),
      }}
    />
  );
};

export const DotDescription = ({ label, description, detail }) => (
  <hgroup style={{ height: LINE_HEIGHT + DOT_HEIGHT, textAlign: 'left' }}>
    <div
      className="mt-0"
      style={{
        fontWeight: '600',
        color: '#000000',
        font: 'normal normal 600 14px/18px Open Sans',
        letterSpacing: '0px',
        marginBottom: '4px',
      }}
    >
      {label} {description}
    </div>
    <p
      style={{
        color: '#6A6A6A',
        fontWeight: '600',
        height: 'max-content',
        marginBottom: 0,
        letterSpacing: '0px',
        font: 'italic normal 600 14px/18px Open Sans',
      }}
    >
      <em>{detail}</em>
    </p>
  </hgroup>
);

const Line = ({ active = false, height }) => {
  const color = active ? '#1b499f' : '#939292';
  const width = active ? '4px' : '3px';
  return (
    <div
      style={{
        backgroundColor: color,
        height: height,
        minHeight: height,
        margin: '0 auto',
        width: width,
      }}
    />
  );
};

export const HistoryTimeline = ({ pickup }) => {
  const timelineAttributes = useMemo(
    () => buildTimelineArray(pickup),
    [pickup],
  );

  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'row',
        minHeight: 'max-content',
      }}
      className="mt-3"
    >
      <section
        style={{
          width: 30,
          marginRight: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {timelineAttributes?.map((timeline, index) => {
          return (
            <div>
              {index !== 0 && (
                <Line
                  active={timeline?.status !== 'upcoming'}
                  height={LINE_HEIGHT}
                />
              )}
              <Dot status={timeline?.status} />
            </div>
          );
        })}
      </section>

      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {timelineAttributes?.map((timeline) => {
          return (
            <div key={timeline.text}>
              <DotDescription
                label={timeline?.text}
                description={timeline?.description}
                detail={getFormattedTime(timeline?.endTime)}
              />
            </div>
          );
        })}
      </section>
    </section>
  );
};
